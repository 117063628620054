import React,{useRef,useEffect,useState} from 'react'

function PropBeds(props) {
const [bedvalue,setbedvalue]=useState(0)
//console.log("propbed",props.options);

const filterbed=(key,val)=>{
  console.log(val);
  setbedvalue(val);
  let objbedFilter={};
 objbedFilter[key]=val
 props.callbackFn(objbedFilter)
}

 return (
   
     <div className="filter_btn_group">
      <button type="button"
          className="btn bg-transparent dropdown-toggle text-uppercase text-black rounded-0"
          id="dropdownMenuButton2" for="dropdownMenuButton2"
          data-toggle="dropdown" aria-expanded="false">
        {bedvalue}+ Beds
      </button>
      <div className="dropdown-menu f-beds rounded-0"
          aria-labelledby="dropdownMenuButton2">
          <li className="dropdown-item py-1">
            <a href="/" data-value="0"
                  className="text-black text-decoration-none"
                  title="Studio">Studio</a>
          </li>
            {
            ('OL_Property_beds_Lookup' in props.options)?
              Object.keys(props.options['OL_Property_beds_Lookup']['beds']).map((item)=>{
                return(
                 <li key={'beds-'+item} className='dropdown-item py-1' 
                     value={item}
                     onClick={(e)=>filterbed("beds",e.target.value)}>{props.options['OL_Property_beds_Lookup']['beds'][item]}</li>
                )      
              }):null
            }   
      </div>
    </div>
    
  )
}

export default PropBeds
