import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const postHeaderFooterList = createAsyncThunk('HeaderFooterList/postHeaderFooterList',
async(_,{rejectWithValue})=>{
 


try {
    const postdata = {
        "module":"headerfooter",
        "action":"getheaderfooter"
    };

 
    let headers = {
       "Content-Type": "application/x-www-form-urlencoded"
      
    }

   const res = await axios.post("https://www.realstoria.com/api/index.html",postdata ,{headers});
   //console.log("HFlist---------------",(res.data));
    return (res.data)
    }catch(error){
        rejectWithValue(error.res.data)
        console.log('error');
      }
    }
);

export const PostHeaderFooterSlice=createSlice({
    name:"HeaderFooterList",
    initialState:{
    HFOptions:{},
    HFLoading:false,
    },
    reducers:{},
    extraReducers:(builder)=>{
    builder
      .addCase(postHeaderFooterList.pending, (state, action) => {
        state.HFLoading=true;
    })
     .addCase(postHeaderFooterList.fulfilled, (state, action) => {
        state.HFLoading=false;
        if('Result' in action.payload)
          state.HFOptions=action.payload.Result;
    })
     .addCase(postHeaderFooterList.rejected, (state, action) => {
       
        state.HFLoading=false;
    })
  }
})

export default PostHeaderFooterSlice.reducer