import React,{useRef,useEffect,useState,createRef, useLayoutEffect} from 'react';
import Gmap from './Gmap';
import PropertyBox from './PropertyBox';
import Autosuggetion from './Autosuggetion';
import SearchFilter from './SearchFilter';
import { RotatingLines } from  'react-loader-spinner';
import { useDispatch,useSelector } from 'react-redux';
import { postPropertyList } from '../ThunkSlices/PropertyLIstSlice';
import { postMenuFilterList } from '../ThunkSlices/FilterMenuSlice';
import { LoadScript} from '@react-google-maps/api';
import { getSelectedFilters,selectFilter } from '../ThunkSlices/PropertyLIstSlice';
import { useLocation,useParams } from "react-router-dom";
import { postHeaderFooterList } from '../ThunkSlices/PostHeaderFooterSlice';
import { getFavouriteList } from '../ThunkSlices/GetFavouriteSlice';
import parse from 'html-react-parser';
import {Image,Shimmer} from 'react-shimmer';


import GMapNew from './GMapNew';
import axios from 'axios';


function MapSearch(props) {
    const mapRef = useRef();
    const frmFilterRef = useRef();
    const listRef = useRef();
    const scrollRefs = useRef({});
    const menuRefs = useRef(null);
    const headerRef = useRef(null);
    
    const dispatch = useDispatch();
    const [mapHeight, setmapHeight] = useState(0);
    const [headHeight, setHeadHeight] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [showPages, setShowPages] = useState([]);
    const [listPages, setListPages] = useState([]);
    const [pageSize, ] = useState(20);
    const [lastpage,setLastPage ] = useState(0);
    const [libraries] = useState(["geometry", "drawing", "places"]);
    const[resultData,setResultData]=useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [frmSubmitted, setFrmSubmitted] = useState(false);
    let [objFilter, setFilters]=useState({});
    
    let [listData, setListData]=useState([]);
    const objSelFilters = useSelector(selectFilter);
    const [totalRecordCL, setTotalRecordCL] = useState(0);
    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [toggleGrid, setToggleGrid] = useState(false);
    const [refresPage, setRefresPage] = useState(true);
    const [mapnw,setMapnw]=useState();
    const {pLoading,listArr,totalRecord, cityBound,startRecord}=useSelector((state)=>state.propertyList);
    const {HFOptions}=useSelector((state)=>state.HeaderFooterList);
    const {favDataList}=useSelector((state)=>state.FavouritesList);
    //console.log(favDataList);
    ////console(HFOptions);
    let defaultPages = 5;
    let activeIndex;
    const location = useLocation();
    let params = decodeURIComponent(location.pathname.replace('/for-property/', ''));
 

    const mapSortOpt = {
        'price|desc': 'Price (High to Low)',
        'price|asc': 'Price (Low to High)',
        'sqft|desc': 'Sqft (High to Low)',
        'sqft|asc': 'Sqft (Low to High)',
        'beds|desc': 'Beds (High to Low)',
        'beds|asc': 'Beds (Low to High)',
        'baths|desc': 'Bath (High to Low)',
        'baths|asc': 'Bath (Low to High)',
      }
    
      const handleSortOption = ( val) => {
        let sortby = val.split("|");
        let objSortby={};
        objSortby['so'] = sortby[0];
        objSortby['sd'] = sortby[1];
        objFilter={...objFilter, ...objSortby}
        setFilters(objFilter)
      }

    useEffect(() => {
        dispatch(postMenuFilterList());
        getParam(params);
        dispatch(postHeaderFooterList());
       //dispatch(getFavouriteList())
    },[]);

    // TODO: Reset page as initial page at every where when get new data from API.
    useEffect(() => {
        let promise;
        //if(Object.keys(objFilter).length > 0){
            //var urlParams = setURLParams(params);
            ////console(objFilter);
            dispatch(getSelectedFilters(objFilter))
            if(objFilter && 'currentLocation' in objFilter){


            }else{
                postUrlParam(objFilter)
                promise=dispatch(postPropertyList(objFilter))
            }

            return()=>{
                if(promise != undefined)
                promise.abort()
            }
        //}
       
    }, [dispatch, objFilter]);

    useEffect(() => {
        setTotalRecordCL(0)
        if(listArr.length > 0){
            setDataForView(listArr, refresPage)
            setRefresPage(false)
        }
        else{
        setListData([])
        setShowPages([]);
       
        }
    }, [listArr]);

    useLayoutEffect(() => {
       
        if (menuRefs.current && headerRef.current)
        calHeigh();

    },[menuRefs,headerRef]);

/*     useEffect(() => {
        ////console("pages click");
    onClickPages()
    }, [currentPage]) */

   const getParam=(params)=>{
    //console.log(params);
        let objParam={};
        if(params.indexOf('/') != -1){
            let arrParam=[];
            arrParam=params.split("/");
            arrParam.map((item,i)=>{
                if(item === '')
                    return;
                
                // Split string from first occurnce only
                let arr=item.split(/-(.*)/s)
                if(arr.length > 1){
                    let objD = {};
                    let val = arr[1]
                   
                    if(val.indexOf(',') >= 0 && (arr[0] != 'addval' && arr[0] != 'map')){
                        val = val.split(',')
                    }

                    objD[arr[0]] = val;
                    objParam={...objParam,...objD}
                }
            })
        }else{
            ////console('IMMMMMMMM');
             // Set default params
            objParam = {
             
                //ptype: 'Residential',
                so:'price',
                sd:'asc',
                page:1,
            }
        }
        objParam = setDefaultFilter(objParam)

////console(objParam);
        setFilters(objParam)
    }

    var arrExclude = ['forsale', 'forincome', 'forcommercial','clat','clng','isNewSearch','nzoom','poly','forrent']
    const postUrlParam=(filtersObj)=>{
        var str ='/for-property/';
        Object.keys(filtersObj).map((item,i)=>{
            if(arrExclude.indexOf(item) < 0){
                
                var d = filtersObj[item];
                //console.log(d);
                if(Array.isArray(filtersObj[item]) && filtersObj[item].length > 0)
                    d = filtersObj[item].join(',').replace(" ", "");
                    //console.log(d);
                if(d != '')
                    str +=  item+'-'+d+'/';
            }
        })
        //var url = window.location.href
        window.history.pushState('','',str)
    }

    const postDetailPageURL=()=>{
   
    }

    const _postUrlParam=()=>{
        ////console(objFilter);
         let arr=[];
        Object.keys(objFilter).map((item,i)=>{
            ////console(item);
        let bItem=item + "-";
        //console(bItem);
        let str=bItem +objFilter[item]+ "/";
        arr.push(str);
        //console(arr);
        let strVal=arr.toString();
          //console(strVal);
         strVal=strVal.replaceAll(",", "");
         //console(strVal);
        let val=objFilter[item];
         if(val.indexOf(',')>=0){
            val=val.split(",");
           
         }
         let strUrl="/"+strVal;
         //console(strUrl);
         //console(val.toString());
       })
  
    }
    const setDefaultFilter = (objParam) => {
        // Set default params
        if(!('page' in objParam))
            objParam['page'] = 1
        if(!('so' in objParam))
            objParam['so'] = 'price'
        if(!('sd' in objParam))
            objParam['sd'] = 'asc'
        
        return objParam
    }
    // Everytime at new call
    const setDataForView = (arrItems, resetPage=false) => {
       
        //console('INNNNNNNNNNNN SETDATAFLOW');
        var initialPage = 1;
        if('page' in objFilter && resetPage === true)
            initialPage = parseInt(objFilter['page'])
        

        if(resetPage === false){
            //console("===============");
            var resetFilter={};
            resetFilter['page'] =  initialPage
             resetFilter = {...objFilter, ...resetFilter};
           
            //console(resetFilter);
            postUrlParam(resetFilter);
        }
        setCurrentPage(initialPage);
        var start_record = (initialPage-1)*pageSize;
        setResultData(arrItems)
        setListData(arrItems.slice(start_record, (pageSize*initialPage)));
       
        getTotalPages(arrItems.length, initialPage);
        
    }

    const calHeigh = () => {
        if (menuRefs.current.clientHeight && headerRef.current.clientHeight) {
            let height = (window.innerHeight - (menuRefs.current.clientHeight))-(headerRef.current.clientHeight) ;
            // let height = (window.innerHeight - (menuRefs.current.clientHeight))-(headerRef.current.clientHeight) ;
           /*  console.log(window.innerHeight)
            console.log(height)
            console.log(menuRefs.current.clientHeight)
            console.log(headerRef.current); */
             //let headerHeight=(window.innerHeight-height + ((menuRefs.current.clientHeight)))+ 'px';
             //console.log(headerHeight);
                //setHeadHeight(headerHeight)
                //headerRef.current.style.height=headerHeight 
                mapRef.current.style.height = height + 'px';
                listRef.current.style.height = height + 'px';
                setmapHeight(height); 
        }
    }
   

    const format = num =>
    String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'); 
    
  
    const getTotalPages = (total_reords, cpage=1) => {
        ////console(cpage);
        listRef.current.scrollTo(0, 0);
        setCurrentPage(cpage)
       
        var pages = [];
        let total_pages = Math.ceil(total_reords / pageSize);
        ////console(total_pages);
        setLastPage(total_pages);
       

        for (var i = 1; i <= total_pages; i++) {
            pages.push(i)
        }
    
        setListPages(pages);
       
        if (cpage < defaultPages) {
            //console("=======================IFF");
            setShowPages(pages.slice(0,defaultPages))
        }
        else if (cpage >= defaultPages && (pages.length - cpage) > 0 && cpage != pages.length) {
            setShowPages(pages.slice(cpage-4,cpage+1))
        }
        else if(cpage === pages.length ){
            //console("=======================last");
            setShowPages(pages.slice(cpage-5,cpage))
        }/* 
        else if((total_pages - cpage) < 4){
            var slicePage = total_pages-defaultPages
            //console("=======================last2", slicePage);
            setShowPages(pages.slice(slicePage, slicePage+defaultPages));
        }
        else{
            var slicePage = cpage-1
            //console("=======================last3");
            setShowPages(pages.slice(slicePage, slicePage+defaultPages));
        } */
       
    }

    const handleIMarkerClick = (index) => {
       
        if (activeIndex != null && activeIndex in scrollRefs.current && scrollRefs.current[activeIndex].current != null){
            scrollRefs.current[activeIndex].current.children[0].className = 'card border-0 text-white overflow-hidden rounded-0 ls-card '
        }
        
        if(index in scrollRefs.current &&  scrollRefs.current[index].current!=null){
            scrollRefs.current[index].current.scrollIntoView({ behavior: "smooth", block: "end"});
            scrollRefs.current[index].current.children[0].className = 'card bg-dark imgScroll text-white overflow-hidden rounded-0 ls-card  border-primary';
        }
        activeIndex = index; 
    }

    const handleMarkerRemoveClick=(id)=>{
        activeIndex = id;
       if(id in scrollRefs.current &&  scrollRefs.current[id].current != null){
        scrollRefs.current[id].current.children[0].className = 'card border-0 text-white overflow-hidden rounded-0 ls-card ';
       }
    }
    
   const  handleclusterClick=(arr)=>{
    //console("======================cluser click");

        setTotalRecordCL(arr.length)
        setDataForView(arr, true)

   }
    const onClickPages= (val) => {
        listRef.current.scrollTo(0, 0);
        let c_page = parseInt(val);
        var start_record=(val - 1) * pageSize;

        getTotalPages(resultData.length, c_page, lastpage)
        setListData(resultData.slice(start_record, (pageSize*val)));
        setPageInURL(c_page)
    }
  const _onClickPages = (val) => {
    ////console(val);
    
    let c_page = parseInt(val);
    setCurrentPage(c_page);
   // //console("cuurpage",currentPage);
   // listRef.current.scrollTo(0, 0);
    // When current page is queal to default page or greater
    ////console("lpage",lastpage);
    if (c_page >= defaultPages && (listPages.length - c_page) > 0 && currentPage != lastpage) {
     
      //setShowPages([]);
      let data = listPages.slice(c_page - 4, c_page + 1);
      ////console(listPages);
      ////console(listPages.slice(c_page - 4, c_page + 1));
      setShowPages(data)

    }else if(c_page === listPages.length){
        //console('INNNNNn');
        let data = listPages.slice(c_page - 5, c_page);
        ////console(listPages);
        ////console(listPages.slice(c_page - 4, c_page + 1));
        setShowPages(data)
    }else{
        // Set default pagination as initial
        //console("pages at initial");
        getTotalPages(resultData.length,c_page,lastpage)
        
    }
    var start_record=(val - 1) * pageSize;
    
    setListData(resultData.slice(start_record, (pageSize*val)));
    setPageInURL(c_page)
   
  }

  const setPageInURL=(page)=>{
    var url = window.location.href
    var sUrl = url.substring(url.indexOf('page') + 0)
    var newStr = sUrl

    if(sUrl != ''){
        // Split string with first occurance (/)
        var arrData = sUrl.split(/\/(.*)/s);
        // Filter blank value from array
        arrData = arrData.filter((val) => val != '')

        if(arrData.length > 0){
            arrData[0] = 'page-'+page           
        }
        newStr = arrData.join('/')
       // if(arrData[0] == 'page')
    }
    window.history.pushState(null, null, url.replace(sUrl, newStr));
  }
  const formSubmit=(event)=>{

    event.preventDefault();
    setIsSubmitted(true);
    //console(event.target);
    var data = new FormData(event.target);
    let formObject = Object.fromEntries(data.entries());
   //console(data);
   //console(data.getAll('ptype'))
   //console(data.get('stype'))
   //console(data.getAll('stype'))
   //console(formObject);
   // For multi selectionbox to getting all selected option value required to do below 
   if('ptype' in formObject){
    formObject['ptype'] = data.getAll('ptype')
   }

   if('stype' in formObject){
    formObject['stype'] = data.getAll('stype')
   }
   var formData = cleanObj(formObject);
   
    //console(formData);


    // HIDDEN FORM DATA
    var filterParam=new FormData(frmFilterRef.current);
  
    let filterObj=Object.fromEntries(filterParam.entries());
    var filterData=cleanObj(filterObj)
    ////console(objSelFilters);
    //if(('map' in objSelFilters )== true ? objSelFilters.map :"")
    setFilters({...formData,...filterData})
    //setFrmSubmitted(frmSubmitted)
   
  }


 const  onSubmit=(submit)=>{
    //console(submit);
    setFrmSubmitted(submit)
 } 

 const cleanObj = (obj) => {
   
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName] === 'Any') {

        delete obj[propName];
      }
    }
    return obj
  }



  const onToggleChange = () => {
    var newToggle = (toggleSwitch) ? false : true;
    setToggleSwitch(newToggle);
  }

  const  handleGridViewClick=(toggle)=>{
    //////console(toggle);
       setToggleGrid(true);
 
    }

    const handleCurrentLocation=(map)=>{
        //////console(map);
        setMapnw(map);
        //////console(lat);
       /*  setUserLat(lat)
        //////console(lng);
        setUserLng(lng) */

    }

    const nextPage = () => {
        if(currentPage != lastpage) 
       
            onClickPages(currentPage + 1)
    }

    const onClearFilters=()=>{
        console.log("==============");
        console.log(objFilter)
        objFilter={}
        const objDefFilter = setDefaultFilter({})
        setFilters(objDefFilter)
        //postUrlParam(objFilter)
     
    }


////console(currentPage);
  return (
    <div className="page-wrapper custom-wrapper idxwrapper d-block">
       <div className='col-12' ref={headerRef}>
          {('header' in HFOptions)?parse(HFOptions.header.toString()):''}
          </div>
          <div id="page-content" className=" padding_bottom" style={{display:'block',marginTop:77}}>
            <div className="container-fluid">
                <div className="row" id="msb-1">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12"  ref={menuRefs} >
                        <form id="ms-filter-form" method="post" role="form" className="ms-filter-form w-100" onSubmit={formSubmit} >
                            <div className="row">
                                <Autosuggetion  setFilters={setFilters}/>
                                <SearchFilter setFilters={setFilters} callbackfrm={onSubmit}/>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 save-clear -px-0 text-end hidden-md-down d-flex justify-content-end">
                                    <button id="save_search" type="button"
                                        className="btn btn-primary nav-save-this-search text-uppercase rounded-0"
                                        data-toggle="modal" data-target="signin">
                                        Save <span className="iconify download-icon text-white"
                                            data-icon="fa-solid:download"></span></button>
                                    <button type="button" className="btn rounded-0 bg-transparent" id="cleare-search" onClick={onClearFilters}>
                                        Clear
                                        <span className="iconify clear-icon text-black" data-icon="ic:round-refresh"></span>
                                    </button>
                                </div> 
                            </div>
                        </form>
                        <form name="frmFilter" id="frmFilter" method="post" role='form'  ref={frmFilterRef}>
                             <input type="hidden" name="page" id="page" value={(('page' in objSelFilters)===true) ? objSelFilters.page :""}/> 
                            <input type="hidden" name="so" id="so" value={(('so' in objSelFilters)===true) ? objSelFilters.so :""}/>
                            <input type="hidden" name="sd" id="sd" value={(('sd' in objSelFilters)===true) ? objSelFilters.sd :""}/>
                            <input type="hidden" name="vt" id="vt" value="map"/>
                        </form>
                    </div>
                </div>
                <div id="msb-3" className="row  overflow-hidden">
                    <div className="col-12 d-none hidden-md-up">
                        <div className="text-right form-check form-switch mb-2 mt-3 w-100">
                            <label className="switch">
                                <input className="switch-checkbox" type="checkbox"
                                       onChange={onToggleChange}/>
                                <span className="switch-slider round"></span>
                            </label><span>Map</span>
                          {/* <label className="form-check-label ps-4" for="flexSwitchCheckChecked"></label> */}

                        </div>
                    </div>
                    
                    <div id="ms-lp" className= {(toggleSwitch ? " " : " hidden-sm-down ") + ' col-12 col-sm-12 '+ (toggleGrid?' hidden-md-up ':' col-xl-6 col-md-6 col-lg-6  px-0 ')} ref={mapRef}>
                      <LoadScript id="script-loader" googleMapsApiKey='AIzaSyDqcryeVS4mHApy8sZB9rhIQOho5_mtgGU' libraries={libraries}>
                      <GMapNew mapHeight={mapHeight} setFilters={setFilters}  objCllbackRef={handleIMarkerClick} objCallbackClose={handleMarkerRemoveClick} callbackCluster={handleclusterClick}  
                                                  callbackgridView={handleGridViewClick} callbackLocation={handleCurrentLocation} isCurrentLocation={objFilter}/>
                    </LoadScript>
                    </div>
                    <div id="ms-rp" className={(toggleSwitch ?  "hidden-sm-down" : "px-0")+ ' col-12 col-sm-12 px-0 '+(toggleGrid?' col-md-12 ':' col-xl-6 col-md-6 col-lg-6 ')  }>
                        <section id="lr-container" className="m-0 ">
                            <div id="lr-data" className="mapscroller" ref={listRef} >
                             <div id="lr-res">
                                <h1 className="text-center text-black" id="property-listing-title"> {(('addval' in objSelFilters)===true) ? (objSelFilters.addval+" "+ "Homes For Sale") :"Homes For Sale"}</h1>
                                {listArr.length > 0?
                                <h2 className="text-center" id="property-cnt">
                                    <span className="results-number result-size text-black">{(totalRecordCL > 0)?format(totalRecordCL):format(totalRecord)} </span>
                                    <span className="result-size text-black"> Properties found</span>
                                </h2>
                                :null}
                                    
                                <form id="lr-criteria-form" className="  mb-lg-3 " name="sm-criteria-form" method="post"
                                        autoComplete="off" role="search" action="">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 hidden-sm-down">
                                            <div className="row">
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-6 lr-sort-by-1">
                                                    <ul className="list-inline m-0" id="so_sd">
                                                        <li className="list-inline-item">
                                                            <div className="h6">Sort By : </div>
                                                        </li>
                                                        <li className="list-inline-item">
                                                            <select name="sort_by" id="sort_by" 
                                                                className="pdmSelect mw-100 py-0 sdw-none custom-select mb-0 rounded-0"
                                                                onChange={(e) => handleSortOption( e.target.value)}>
                                                                   {
                                                                    Object.keys(mapSortOpt).map((item, i) => { 
                                                                        let sortI=item.split('|')
                                                                        return(
                                                                        <option key={"sort-" + item} value={item} selected={('so' in objSelFilters && objSelFilters['so'] === sortI[0]) && ('sd' in objSelFilters && objSelFilters['sd'] === sortI[1])}>
                                                                        {mapSortOpt[item]}
                                                                        </option>
                                                                    )})
                                                                    }
                                                              </select>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-4 col-lg-3 lr-sort-by-1"> <button
                                                        id="market_prices" type="button"
                                                        className="btn btn-primary rounded-0 d-none">Market Prices</button>
                                                </div>
                                              
                                                <div className={"col-12 col-sm-12 col-md-4 col-lg-3 lr-sort-by-1 map_view "+ (toggleGrid?' d-block ':'d-none ') }>
                                                        <a className="btn btn-secondary btn-sm float-right btn-grid"
                                                            id="btn_grid_off" data-mode="grid" href="/"
                                                            title="Grid View"><i className="fa fa-globe fa-md"></i> Map View
                                                        </a>
                                                </div>
                                            </div>
                                        </div>

                              
                                    </form>
                                   
                                  
                                    <div id="lr-result" className="row m-0 mt-md-2">
                                        {
                                            
                                            (listData.length > 0 ) ?    
                                                listData.map((item,i)=>{
                                                    scrollRefs.current[item.ListingID_MLS] = createRef();
                                                    return(
                                                        <div className={"col-12 col-sm-12 "+(toggleGrid?'col-md-4 col-lg-3 col-xl-3':'col-md-12 col-lg-6 col-xl-6')+" lr-items position-relative"} key={item.ListingID_MLS} ref={scrollRefs.current[item.ListingID_MLS]} >
                                                    <PropertyBox data={item} />
                                                    </div>
                                                    )
                                                })
                                            :(!pLoading && totalRecord === 0)?<>
                                            
                                                <div className="col-12 hidden-md-up">
                                                    <div className="text-right mb-2">{"gfgfhghjgj"}</div>
                                                </div>
                                                <div className="col-12 text-center h6">Sorry, we did not find any properties matching your request.
                                                    <br/>Please modify your search criteria and try again.
                                                </div>
                                                </>:
                                                <>
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 lr-items position-relative' >
                                                        <Shimmer width={441} height={220} />
                                                    </div>
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 lr-items position-relative' >
                                                        <Shimmer width={441} height={220} />
                                                    </div>   
                                                
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 lr-items position-relative' >
                                                        <Shimmer width={441} height={220} />
                                                    </div>
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 lr-items position-relative' >
                                                        <Shimmer width={441} height={220} />
                                                    </div>   
                                                
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 lr-items position-relative' >
                                                        <Shimmer width={441} height={220} />
                                                    </div>
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 lr-items position-relative' > 
                                                        <Shimmer width={441} height={220} />
                                                    </div>   
                                                </>
                                        }
                                    </div>
                                     
                                    
                                {showPages.length > 0?
                                    <div className="lr-pagination mt-4">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item mx-1 align-self-center ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <a className="page-link border-0 text-black rounded-circle " href="#!"
                                                        aria-label="Previous"  onClick={()=>onClickPages(currentPage-1)}
                                                         >
                                                     {/* <span aria-hidden="true">&laquo;</span> */} 
                                                        <span aria-hidden="true" className="iconify page_icon"
                                                            data-icon="bxs:chevron-left"></span>
                                                    </a>
                                                </li>
                                                {showPages.map((item,i)=>{
                                                    return(
                                                    <li className={`page-item  ${currentPage === item  ? 'active' : ""}`} key={"newpage"+item} value={item}>
                                                    <a className="page-link " 
                                                    href="#/" onClick={(e) => onClickPages( e.target.dataset.val)} data-val={item}>{item}</a>
                                                    </li>
                                                    )
                                                 })}
                                             

                                                <li className={`page-item mx-1 align-self-center ${currentPage === lastpage ? 'disabled' : ''}`}>
                                                    <a className="page-link border-0 text-black rounded-circle" href="#!"
                                                        aria-label="Next"  onClick={()=>nextPage()}>
                                                      {/*    <span aria-hidden="true">&raquo;</span>  */}
                                                        <span aria-hidden="true" className="iconify page_icon"
                                                            data-icon="bxs:chevron-right"></span> 
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>:null}
                                    
                                </div>
                                {('footer' in HFOptions)?parse(HFOptions.footer.toString()):''}
                                {/* <footer id="page-footer" className="d-block">
                                    <div className="footer-wrapper ">
                                        <div className="block">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12  col-lg-12 ">
                                                        <div className="h5 f-c-black py-5">Popular Searched City</div>
                                                        <ul className="nav flex-column a-default">
                                                            <div className="row">
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Clewisto"
                                                                        title="Clewisto, Fl Real Estate">Clewisto Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Cooper_City"
                                                                        title="Cooper City, Fl Real Estate">Cooper City
                                                                        Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Coral_Springs"
                                                                        title="Coral Springs, Fl Real Estate">Coral
                                                                        Springs
                                                                        Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Hillsboro_Beach"
                                                                        title="Hillsboro Beach, Fl Real Estate">Hillsboro
                                                                        Beach Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Hypoluxo"
                                                                        title="Hypoluxo, Fl Real Estate">Hypoluxo Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Lakeworth"
                                                                        title="Lakeworth, Fl Real Estate">Lakeworth Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Loxahatchee"
                                                                        title="Loxahatchee, Fl Real Estate">Loxahatchee
                                                                        Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Mangonia_Park"
                                                                        title="Mangonia Park, Fl Real Estate">Mangonia
                                                                        Park
                                                                        Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/North_Palm_Beach"
                                                                        title="North Palm Beach, Fl Real Estate">North
                                                                        Palm
                                                                        Beach Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Palm_City"
                                                                        title="Palm City, Fl Real Estate">Palm City Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Palmetto_Bay"
                                                                        title="Palmetto Bay, Fl Real Estate">Palmetto
                                                                        Bay
                                                                        Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Parkland"
                                                                        title="Parkland, Fl Real Estate">Parkland Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Pembroke_Pines"
                                                                        title="Pembroke Pines, Fl Real Estate">Pembroke
                                                                        Pines Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/Unincorporated_Dade_County"
                                                                        title="Unincorporated Dade County, Fl Real Estate">Unincorporated
                                                                        Dade County Real Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/West_Miami"
                                                                        title="West Miami, Fl Real Estate">West Miami
                                                                        Real
                                                                        Estate</a>
                                                                </li>
                                                                <li
                                                                    className="nav-item col-12 col-sm-6 col-md-6 col-lg-6 m-0 p-0">
                                                                    <a className="nav-link"
                                                                        href="https://www.realstoria.com/for-sale/Fl/West_Park"
                                                                        title="West Park, Fl Real Estate">West Park Real
                                                                        Estate</a>
                                                                </li>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6  col-lg-6 mt-sm-2 mt-md-2">
                                                        <div className="h5 f-c-black py-5">Company</div>
                                                        <ul className="nav flex-column a-default">
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/disclaimer"
                                                                    title="Disclaimer">Disclaimer</a></li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/terms-of-use"
                                                                    title="Terms of Use">Terms of Use</a></li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/privacy-policy"
                                                                    title="Privacy Policy">Privacy Policy</a></li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/real-estate-investing"
                                                                    title="Real Estate Investing">Real Estate
                                                                    Investing</a>
                                                            </li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/online-real-estate-marketplace-to-buy-and-sell-homes-virtually-online"
                                                                    title="Online Real Estate">Online Real Estate</a>
                                                            </li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/florida-luxury-homes"
                                                                    title="Florida Luxury Homes">Florida Luxury
                                                                    Homes</a>
                                                            </li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/boca-raton-condos"
                                                                    title="Boca Raton Condos">Boca Raton Condos</a></li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/dmca-notice"
                                                                    title="DMCA Notice">DMCA Notice</a></li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/luxury-homes"
                                                                    title="Luxury Homes">Luxury Homes</a></li>
                                                            <li className="nav-item"><a className="nav-link pl-0"
                                                                    href="https://www.realstoria.com/waterfront-homes"
                                                                    title="Waterfront Homes For Sale">Waterfront Homes
                                                                    For
                                                                    Sale</a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-12 col-sm-6 col-md-6  col-lg-6 mt-sm-2 mt-md-2">
                                                        <div className="h5 f-c-black py-5">Customer Service</div>
                                                        <p>Toll Free 561-614-5353, International </p>
                                                        <p>Helpline (+1)561-614-5353</p>
                                                        <p>Support Info@RealStoria.com</p>
                                                        <p>561-614-5353</p>
                                                        <div className="h5 f-c-black mt-4">Follow Us</div>
                                                        <div className="element"> <a
                                                                href="https://www.facebook.com/realstoriarealestate"
                                                                className="circle-icon" target="_blank" title="facebook"
                                                                rel="noreferrer"><i className="social_facebook"></i></a> <a
                                                                href="https://twitter.com/RealStoria"
                                                                className="circle-icon" target="_blank" title="twitter"
                                                                rel="noreferrer"><i className="social_twitter"></i></a> <a
                                                                href="https://g.page/r/CYyH6M3jRkLAEB0/review"
                                                                className="circle-icon" target="_blank" title="google plus"
                                                                rel="noreferrer"><i className="social_googleplus"></i></a> <a
                                                                href="https://www.linkedin.com/company/realstoria/"
                                                                className="circle-icon" target="_blank" title="linkedin"
                                                                rel="noreferrer"><i className="social_linkedin"></i></a> <a
                                                                href="https://www.youtube.com/channel/UCecu04AEb5UFv1Jr1vfxN9w"
                                                                className="circle-icon" target="_blank" title="youtube"
                                                                rel="noreferrer"><i className="social_youtube"></i></a> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="block">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="circle-icon"><i className="fa fa-question fa-lg"></i>
                                                        </div>
                                                        <span className="f-c-black Q-text pl-2">Why Realstoria.com</span>
                                                        <p className="pl-5 text-justify">The Real Estate Market Place for
                                                            Everybody. Source for Real Estate Services, Homes For Sales,
                                                            Luxury and Waterfront Properties and Market Information.
                                                            RealStoria.com is a customer oriented website for real
                                                            estate
                                                            listings for sale and rent, local information&nbsp;about
                                                            local
                                                            areas, market prices and new construction projects. In
                                                            partnership with The Day &amp; Jekov Group at Charles
                                                            Rutenberg
                                                            Realty we are committed to providing value and quality
                                                            service
                                                            to our clients. Our success is build on our values and
                                                            integrity
                                                            on every aspect of real estate transaction. We strive to
                                                            partner
                                                            with top local agents only that share our commitment to the
                                                            highest industry standards and business practices.&nbsp;</p>
                                                        <div id="urban_overlay">&nbsp;</div>
                                                        <div id="urban_overlay" style={{left:-10,top:-10,width:0,height:0}}

  
 

>&nbsp;</div>
                                                        <div id="urban_overlay">&nbsp;</div>
                                                        <div id="urban_overlay" style={{left:-10,top:-10,width:0,height:0}}>&nbsp;</div>
                                                        <div id="urban_overlay">&nbsp;</div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="circle-icon"><i className="fa fa-info fa-lg"></i></div>
                                                        <span className="f-c-black Q-text pl-2">Disclaimer</span>
                                                        <p className="acenter pl-5 text-justify">Local Real Estate
                                                            Services&nbsp;Provided By: Charles Rutenberg Realty LLC,
                                                            Address: 2201 W Prospect Rd, #200, Fort Lauderdale, FL
                                                            33309,
                                                            The Day &amp; Jekov Group, Telephone 800-844-1315,
                                                            561-614-5353,
                                                            Information deemed RELIABLE but not GUARANTEED, Website
                                                            owned
                                                            and provided by RealStoria Network.</p>
                                                        <p className="acenter pl-5 text-justify">* We are continually
                                                            working
                                                            and improving the user experience for&nbsp; people with
                                                            disabilities and applying relevant accessibility standards
                                                            to
                                                            ensuring digital accessibility for everyone.</p>
                                                        <p className="acenter pl-5 text-justify">Copyright: © RealStoria.com
                                                            2023 All Rights Reserved.</p>
                                                        <p className="acenter pl-5 text-justify">&nbsp;</p>
                                                        <p className="acenter pl-5 text-justify">THIS IS A SUMMARY OF ACTIVE
                                                            PROPERTIES FOR SALE &amp; NOT AN OPINION OF VALUE AND SHOULD
                                                            NOT
                                                            BE CONSIDERED AN APPRAISAL. This report does not follow the
                                                            guidelines for development of an appraisal or analysis
                                                            contained
                                                            in the Uniform Standards of Professional Appraisal Practice
                                                            of
                                                            the Appraisal Foundation.</p>
                                                        <p className="acenter pl-5 text-justify">&nbsp;</p>
                                                        <p className="acenter pl-5 text-justify">All information is deemed
                                                            reliable but not guaranteed by the MLS and developers and
                                                            should
                                                            be independently verified. All properties are subject to
                                                            prior
                                                            sale, change, or withdrawal. Neither listing broker(s) nor
                                                            RealStoria.com and Charles Rutenberg Realty brokers and
                                                            agents
                                                            &nbsp;shall be responsible for any typographical errors,
                                                            misinformation, or misprints, and shall be held totally
                                                            harmless
                                                            from any damages arising from reliance upon these data. ©
                                                            MLS of
                                                            MAR. The information being provided is for consumers’
                                                            personal,
                                                            non-commercial use and may not be used for any purpose other
                                                            than to identify prospective properties consumers may be
                                                            interested in purchasing.&nbsp;Realstoria.com agents are
                                                            licensed independent real estate agents. The Day and Jekov
                                                            Group&nbsp;at Charles Rutenberg Realty do not represent the
                                                            developer of the project in any way nor are part of the
                                                            management office. The Cash Rebate bonus is only available
                                                            with
                                                            the purchase and/or sale of your home through the use of a
                                                            program-approved real estate agent. All real estate
                                                            transactions, limited time sales, online bargains, rebated
                                                            commissions, discounts, cash rebates, and other related
                                                            promotional programs&nbsp;are negotiable will be credited to
                                                            the
                                                            buyer listed on the closing documents upon successful
                                                            closing
                                                            and subject to lender approval prior closing. Buyer must
                                                            enter
                                                            an Exclusive Buyer Representation Agreement and purchase a
                                                            home
                                                            offering a minimum 2.5% commission (Or dollar value
                                                            equivalent)
                                                            to Charles Rutenberg Realty LLC. The Rebate is only paid to
                                                            the
                                                            name(s) registered on the agreement of purchase and sale.
                                                            The
                                                            rebate amount must be disclosed to both parties of the
                                                            transaction. Rebates are not valid with any other offers or
                                                            promotions.Other terms and conditions may apply. All terms
                                                            are
                                                            subject to change. Some other conditions and restrictions
                                                            including but not limited to; home inspection, attorney’s
                                                            approval, financing approval and other real estate buying
                                                            and/or
                                                            selling related qualifications may apply.</p>
                                                        <div id="urban_overlay" style={{left:-10,top:-10,width:0,height:0}}>&nbsp;</div>
                                                        <div id="urban_overlay" style={{left:-10,top:-10,width:0,height:0}}>&nbsp;</div>
                                                        <div id="urban_overlay">&nbsp;</div>
                                                        <div id="urban_overlay" style={{left:-10,top:-10,width:0,height:0}}>&nbsp;</div>
                                                        <div id="urban_overlay">&nbsp;</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="footer-navigation  mb-md-5">
                                            <div className="container">
                                                <div className="vertical-aligned-elements">
                                                    <div className="element width-50">Copyright © 2023 RealStoria. All
                                                        Rights
                                                        Reserved.</div>
                                                    <div className="element width-50 text-align-right"> <a
                                                            href="https://www.realstoria.com/disclaimer"
                                                            title="Disclaimer">Disclaimer</a> <a
                                                            href="https://www.realstoria.com/terms-of-use"
                                                            title="Terms of Use">Terms of Use</a> <a
                                                            href="https://www.realstoria.com/privacy-policy"
                                                            title="Privacy Policy">Privacy Policy</a> <a
                                                            href="https://www.realstoria.com/real-estate-investing"
                                                            title="Real Estate Investing">Real Estate Investing</a> <a
                                                            href="https://www.realstoria.com/online-real-estate-marketplace-to-buy-and-sell-homes-virtually-online"
                                                            title="Online Real Estate">Online Real Estate</a> <a
                                                            href="https://www.realstoria.com/florida-luxury-homes"
                                                            title="Florida Luxury Homes">Florida Luxury Homes</a> <a
                                                            href="https://www.realstoria.com/boca-raton-condos"
                                                            title="Boca Raton Condos">Boca Raton Condos</a> <a
                                                            href="https://www.realstoria.com/dmca-notice"
                                                            title="DMCA Notice">DMCA Notice</a> <a
                                                            href="https://www.realstoria.com/luxury-homes"
                                                            title="Luxury Homes">Luxury Homes</a> <a
                                                            href="https://www.realstoria.com/waterfront-homes"
                                                            title="Waterfront Homes For Sale">Waterfront Homes For
                                                            Sale</a>
                                                        <a href="/" data-url="/register.html"
                                                            id="modal-siunup-form" data-toggle="modal"
                                                            data-target="signup" data-dismiss="modal"
                                                            className="popup-modal-md" title="sign up">Sign
                                                            Up</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </footer> */}
                              {/*   {pLoading ? <div className='for_loader'>
                                <div className="text-center" >
                                    <RotatingLines
                                        strokeColor="#ffa500"
                                        strokeWidth="5"
                                        animationDuration="0.75"
                                        width="60"
                                        visible={true}
                                    /> 
                                </div> 
                            </div>:null} */}
                            </div>
                           
                            
                        </section>
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
  )
}

export default MapSearch
