import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const postAddToFavourite = createAsyncThunk('addToFavouriteList/postAddToFavourite',
async(_,{rejectWithValue})=>{
 


try {
    const postdata = {
        "key":"e655785894fde4dcd1c610d0a722e5bc",
        "module":"user",
        "action":"managefavorite",
        "filter":JSON.stringify({ "user_email":"t11@gmail.com",
                                  "mls_num":"R10670046-3",
                                  "action":"Add"
                                })
    };

 
    let headers = {
       "Content-Type": "application/x-www-form-urlencoded"
      
    }

   const res = await axios.post("https://www.realstoria.thatsend.net/api/index.html",postdata ,{headers});
   console.log("favouritelist---------------",(res.data));
    return (res.data)
    }catch(error){
        rejectWithValue(error.res.data)
        console.log('error');
      }
    }
);

export const PostAddFavouriteSlice=createSlice({
    name:"addToFavouriteList",
    initialState:{
    favouriteList:[],
    favLoading:false,
    },
    reducers:{},
    extraReducers:(builder)=>{
    builder
      .addCase(postAddToFavourite.pending, (state, action) => {
        state.favLoading=true;
    })
     .addCase(postAddToFavourite.fulfilled, (state, action) => {
        state.favLoading=false;
        state.favouriteList=action.payload;
    })
     .addCase(postAddToFavourite.rejected, (state, action) => {
       
        state.favLoading=false;
    })
  }
})

export default PostAddFavouriteSlice.reducer