import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { API_URL } from "../constant";

export const postAutoSearchList = createAsyncThunk('AutoSearchList/postAutoSearchList',
async(Keyword,{rejectWithValue})=>{
 //console.log("++++++++++++++Post AutosearchList Api+++++++++++");
//console.log(Keyword);

try {
    const postdata = {
        "key":"TE07REALSTRTWENTYTHREE",
        "keywords":Keyword
       };

   
    let headers = {
       "Content-Type": "application/x-www-form-urlencoded"
      
       }

      //console.log(headers);
    
   
   const res = await axios.post(API_URL+"/autosuggetion",postdata ,{headers});
   //console.log("serchlist---------------",(res.data));
    return (res.data)
    }catch(error){
        rejectWithValue(error.res.data)
        console.log('error');
      }
    }
);

export const AutoSearchSlice=createSlice({
    name:"AutoSearchList",
    initialState:{
      Searchlist:[],
      optionGrp: {},
      SearchLoading:false,
    },
    reducers:{},
    extraReducers:(builder)=>{
    builder
      .addCase(postAutoSearchList.pending, (state, action) => {
        state.SearchLoading=true;
     })
     .addCase(postAutoSearchList.fulfilled, (state, action) => {
         state.SearchLoading=false;
         var prepareData = {};
        let optgroupOrder= ['cs','sub', 'zip', 'add', 'mls', 'area', 'bn']
         if(Array.isArray(action.payload) && action.payload.length > 0){
            action.payload.map((item,i)=>{
              if(!(item.type in prepareData)){
                  var gTitle = (item.type === 'cs')?'CITIES':(item.type === 'sub')?'NEIGHBOURHOODS':(item.type === 'area')?'AREA':(item.type === 'bn')?'BUILDING NAME':(item.type === 'zip')?'ZIPCODE':(item.type === 'mls')?'MLS #':'Address';
                  prepareData[item.type] = {
                     title:gTitle,
                     options:[item.title],
                  }
               }else{
                  prepareData[item.type]['options'].push(item.title)
               }
               //return prepareData;
            })
         }
         
         state.optionGrp = prepareData;
         //console.log(prepareData);
     })
     .addCase(postAutoSearchList.rejected, (state, action) => {
        state.SearchLoading=false;
      
     })
    }
})

export default AutoSearchSlice.reducer