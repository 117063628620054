import React from 'react';
import {Route,Routes,BrowserRouter} from 'react-router-dom';
import MapSearch from './Component/MapSearch';
import Gmap from './Component/Gmap';
import PropertyBox from './Component/PropertyBox';
import DrawingComponent from './Component/DrawingComponent';
import PropBeds from './Component/PropBeds';
import GMapNew from './Component/GMapNew';
import '../src/css/style.css';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={< MapSearch/>}/>
             <Route exact path="/for-property/*" element={< MapSearch/>}/> 
             {/* <Route exact path="/for-sale/:beds?/:baths?/:minprice?/:maxprice?/:minsqft?/:maxsqft?/:minacreage?/:maxacreage?/:maxyear?/:yearbuilt?/:hoafee?/:hoafqncy?/:kword?/:iswaterfront?/:shortsale?/:closure?
            /:ispricereduce?/:status?/:url?/:petsallowed?/:ishoa?/:ptype?/:stype?/:cir?/:latitude?/:longitude?/:poly?/:addtype?/:addval?/"
                   element={< MapSearch/>}/> */}
         {/*    <Route exact path="/Gmap" element={< Gmap/>}/> */}
            <Route exact path="/GMapNew" element={< GMapNew/>}/>
            <Route exact path="/PropertyBox" element={< PropertyBox/>}/>
            <Route exact path="/DrawingComponent" element={< DrawingComponent/>}/>
            <Route exact path="/PropBeds" element={< PropBeds/>}/>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
