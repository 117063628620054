import React,{useState,useEffect,useRef} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { postAutoSearchList } from '../ThunkSlices/AutoSearchSlice';
import { RotatingLines } from  'react-loader-spinner';
import { selectFilter } from '../ThunkSlices/PropertyLIstSlice';

function Autosuggetion({setFilters}) {
const dispatch = useDispatch();
const[value,setvalue]=useState('');
const[showing,setShowing]=useState(false);
const[location,setLocation]=useState(false);
const[searchKey,setSearchKey]=useState();
const[searchVal,setSearchVal]=useState();
const[userLat, setUserLat]= useState();
const[userLong, setUserLong]= useState();
let[windowClick,setWinClick]=useState(false);
const ref = useRef(null);
const refParent = useRef(null);
const addRef = useRef(null);
const {optionGrp,SearchLoading}=useSelector((state)=>state.AutoSearchList);
const objSelFilters = useSelector(selectFilter);

useEffect(() => {
 if(('addval' in objSelFilters) == true){
   setvalue(objSelFilters['addval']);
 }
 if('latitude' in objSelFilters && 'longitude' in objSelFilters){
    setvalue("Current Location")
  }
  
}, [objSelFilters])

useEffect(() => {
    
    // Set delay becuase of when continue typing in searchbox that time it call api multiple time so to prevent this, only call api once stop typing
    const delayDebounceFn = setTimeout(() => {
      ////console.log(value)
      if(value.length >=2)
      dispatch(postAutoSearchList(value));
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [value])

  
  
// Need some logic to manage outside click and search with entered text in addres field.
// TODO: We need some other logic here becuase when windows click is enable that time it not takes the option click event.
// useEffect(() => {
//     //console.log('useEffect windowClick :', windowClick);
//      if(windowClick === true)
//         document.addEventListener('click', handleClickOutside, true);
    
//     return () => {
//         //console.log('CLOSE CLICK');
//         document.removeEventListener('click', handleClickOutside, true);
//     } 

// }, [windowClick])

/* 
const handleClickOutside = (event, enableCall=true) => {
    
    //console.log(ref.current);
    //console.log(!ref.current.contains(event.target));
//console.log(event);
//console.log(event.target.value);
    // When clicking on ouside of Address input property
    setLocation(false);
    setShowing(false);
    // If reference is set and target click not belongs to reference data that means user can not choose any option from suggestion and click at some other area that time need to search with enterd text in input
    // And remove the window click event.
    if ((ref.current && !ref.current.contains(event.target)) ) {
        //document.removeEventListener('click', handleClickOutside, true);
        //console.log('IIFFFFFFF');
        setWinClick(!windowClick)
        //console.log(addRef.current.value);
        if(addRef.current.value.length >= 2 && addRef.current.value !== objSelFilters.addval){
            filterSearch('all', addRef.current.value)
        }
    }else{
        // When select from option it will go here.
        // Although the option click event is enabled, it is not working because it considers window click first.
        // TODO: We need some other logic here becuase when windows click is enable that time it not takes the option click event.
        //console.log('ELELELELELEL');
        setWinClick(!windowClick)
    }
};
const _handleClickOutside = (event, enableCall=true) => {
    // When clicking on ouside of Address input property
  if ((ref.current && !ref.current.contains(event.target)) ) {
    //////console.log("click innnnnn");
    setLocation(false);
    setShowing(false);
    document.removeEventListener('click', handleClickOutside, true);
    ////console.log(addRef.current.value);
    ////console.log(windowClick);
    if(enableCall == true){
        filterSearch('all', addRef.current.value)
    }
    setWinClick(!windowClick)
   // setWinClick(false);
   
    //setvalue(value)
  }
}; */

const onChange=(e)=>{

    setvalue(e.target.value);
    setLocation(false);
    if(e.target.value.length >= 2){
      //dispatch(postAutoSearchList(value));
      setShowing(true)
     }else{
      setShowing(false)
     }
}

const onkeydown=(e)=>{
    // Search on Enter
    if (e.keyCode === 13 ) {
        filterSearch('all', e.target.value)
    }
}

const filterSearch=(k,val)=>{
    //console.log('win',windowClick);
    // When choosing from options that time need to stop windows outside click other wise it will re call api with addval-all
    //setWinClick(false);
    
    ////console.log(windowClick);

    setSearchKey(k);
    setSearchVal(val);
    var ojbsearch={};
    setShowing(false)
    setvalue(val);
    ojbsearch['addtype'] = k
    ojbsearch['addval'] = val;
    ojbsearch['isNewSearch'] = '1';
    //console.log(ojbsearch);
    setFilters((currentState) => {
        var dataFL = {...currentState, ...ojbsearch};
        ////console.log(dataFL);
        delete dataFL['map'];
        if('addtype' in dataFL && (dataFL.addtype == 'cs' || dataFL.addtype == 'address' || dataFL.addtype == 'mls' || dataFL.addtype == 'bn' || dataFL.addtype == 'zip' || dataFL.addtype == 'area'))
            delete dataFL['latitude']; 
            delete dataFL['longitude'];

            if('addtype' in dataFL &&  dataFL.addtype == 'sub'){
                 dataFL = {...currentState, ...ojbsearch};
              }
          

        return dataFL
    })
    //setWinClick(windowClick=>!windowClick)
}

const onSearchButton=()=>{
    setShowing(false)
    let objSearchBtn={};
    objSearchBtn['addtype']="all";
    objSearchBtn['addval']=addRef.current.value;
    setFilters(objSearchBtn)
}

const onInputClick=(e)=>{
    
   //setWinClick(!windowClick);
    
    //setWinClick(true);
    //document.addEventListener('click', handleClickOutside, true);
    setLocation(true);
}

const onCurrentLocation=()=>{
    let objGeoLocation={};
    objGeoLocation['currentLocation']=true;
    setFilters({...objGeoLocation, ...objSelFilters})
    setvalue("Current Location")
    setLocation(false)
}

  return (
<div className="col-12 col-sm-12 col-md-12 col-lg-4 px-0 text-left" ref={refParent}>

     <div className="input-group search_input_block mb-0"  ref={ref}>
        <div className='position-relative w-100'>
        <input type="text" className={((SearchLoading)?'loading':'')+ ' form-control bg-white search_input rounded-0 w-100'}
            placeholder={"Enter city, neighbourhood, address, zipcode, MLS#, Area or Building Name"}
            aria-label="Enter city, neighbourhood, address, zipcode, MLS#, Area or Building Name"
            aria-describedby="button-addon2"
            value={value}
            onChange={onChange}
            onKeyDown={onkeydown} 
            onKeyDownCapture={()=>setLocation(true)}
            name="addval"
            onClick={(e)=>onInputClick(e)}
            ref={addRef}
            />
           
            <div className='loader'>
            {SearchLoading ?
                 <RotatingLines
                 strokeColor="gray"
                 strokeWidth="3"
                 animationDuration="0.75"
                 width="25"
                 visible={true}
               />
               :null}
          </div>
            
          {/* <input name="addval" type="hidden" className="" id="addval" value={(("addval" in objSelFilters) === true )? objSelFilters.addval: ""}></input> */}
          <input name="addtype" type="hidden" className="" id="addtype" value={(("addtype" in objSelFilters) === true) ? objSelFilters.addtype: ""}></input>
          <input name="latitude" type="hidden" className="" id="latitude" value={(("latitude" in objSelFilters) === true) ? objSelFilters.latitude: ""}></input>
          <input name="longitude" type="hidden" className="" id="longitude" value={(("longitude" in objSelFilters) === true) ? objSelFilters.longitude: ""}></input> 
      
            { (location || showing)?
                <div className="selectize-dropdown AddressName for-search single plugin-restore_on_type w-100" style={{left:0,top:39}}>
                    <div className={((showing)?'loading':'')+' selectize-dropdown-content shadow-sm '}>
                        {location?
                            <div data-value="Current Location" data-selectable="" className="dropdownrow active -option" onClick={()=>onCurrentLocation()}>Current Location</div>:
                            showing?
                            Object.keys(optionGrp).map((searchin)=>{
                                return(
                                    <div data-group={searchin} className="optgroup" key={"grp"+searchin}>
                                        <div className=" group-header" key={"gtitle"+searchin}>{optionGrp[searchin]['title']}</div>
                                        {
                                            optionGrp[searchin]['options'].map((item,i)=>{
                                                return(
                                                    <div data-val={item} key={"glist"+item+i} className="dropdownrow -option" onClick={(e)=>{filterSearch(searchin,item);}}>
                                                        {item}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    
                                )
                            }):null
                        }
                        
                        {(!location && value)?
                        <div className='current active'>
                        <div data-value="mia" className="dropdownrow -option">{value}</div></div>:null}
                    </div>
                    
                    
                </div>:null
        }
      
    </div>
    <button className="btn btn-primary search_btn text-uppercase rounded-0 " type="button" id="button-addon2" 
                onClick={()=>onSearchButton()}>
            Search
    </button>
    </div>
</div>
  )
}

export default Autosuggetion
