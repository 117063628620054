/* global google */
import React,{useRef} from "react";
import { DrawingManager } from "@react-google-maps/api";

const DrawingComponent = () => {
  const onLoad = (drawingManager) => {
    console.log("drawingManager", drawingManager);
  };

  const onPolygonComplete = (polygon) => {
    console.log("polygoncomplete", polygon);
  };

  const drawingManagerRef = useRef(null);

  const handleCustomButtonClick = () => {
    if (drawingManagerRef.current) {
      // Get the drawing manager instance
      const drawingManager = drawingManagerRef.current.drawingManager;

      // Add your custom logic to handle the custom button click event
      console.log('Custom button clicked');

      // Example: Enable drawing mode
      drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    }
  };

  return (
    <DrawingManager
    ref={drawingManagerRef}
      onLoad={onLoad}
      onPolygonComplete={onPolygonComplete}
      options={{
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON,google.maps.drawing.OverlayType.CIRCLE]
        },
        polygonOptions: { editable: true ,}
      }}
    />
  );
};

export default DrawingComponent;
