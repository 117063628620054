import { configureStore } from "@reduxjs/toolkit";
import propertyListReucer from './ThunkSlices/PropertyLIstSlice';
import searchListReducer from './ThunkSlices/AutoSearchSlice';
import filterListReducer from './ThunkSlices/FilterMenuSlice';
import headerFooterListReducer from './ThunkSlices/PostHeaderFooterSlice';
import addFavouriteReducer from './ThunkSlices/PostAddFavouriteSlice';
import removefavReducer from './ThunkSlices/PostRemoveFavSlice';
import getListFavReucer from './ThunkSlices/GetFavouriteSlice';

export default configureStore({
    reducer:{
   
        propertyList:propertyListReucer,
        AutoSearchList:searchListReducer,
        MenuFilterList:filterListReducer,
        HeaderFooterList:headerFooterListReducer,
        addToFavouriteList:addFavouriteReducer,
        removefavList:removefavReducer,
        FavouritesList:getListFavReucer
  },

        middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
})
