import React,{useState,useEffect} from 'react';
import Slider from "react-slick";
import { useDispatch,useSelector } from 'react-redux';
import { postAddToFavourite } from '../ThunkSlices/PostAddFavouriteSlice';
import { postRemoveFavourite } from '../ThunkSlices/PostRemoveFavSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function PropertyBox(props) {
const dispatch = useDispatch();
 let item=props.data;
  var pricecir;
  var str ;


useEffect(() => {
//dispatch(postAddToFavourite())
//dispatch(postRemoveFavourite())
}, [])   

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
   });

const format = num =>
   String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

let  status = '', status_class = ''
if(item.PropertyType === 'Rental' ||  item.PropertyType === 'ResidentialLease'){
    status = "For Rent"; 
    status_class = 'badge-rental  badge-rent';
}
else if(item.ListingStatus === "Active"){
    status = "For Sale";
    status_class="badge-primary  badge-sale";

    if (item['Price_Diff'] < 0 ) {

      status_class = 'badge-price_reduced  badge-sale';
        
    } else if (item['DOM'] <= 7 ) {
        
        status_class = 'badge-just_listed  badge-sale';
        
    } else if ((item['SubType'] === 'Condominium' || item['SubType'] === 'Townhouse' || item['SubType'] === 'Villa') ) {
        
        status_class = 'badge-condos_townhouses_villa badge-sale';

    } else if ((item['PropertyType'] === 'Residential' || item['SubType'] === 'Single Family Residence' || item['SubType'] === 'SingleFamilyResidence')) {
        status_class = 'badge-house badge-sale';
        
    } else if ((item['SubType'] === 'Multi Family' || item['SubType'] === 'MultiFamily' || item['SubType'] === 'Duplex' || item['SubType'] === 'Quadruplex' || item['SubType'] === 'Triplex' || item.PropertyType === 'Income')) {
        status_class = 'badge-multifamily_duplex_triplex badge-sale';
    }
    else if ((item["PropertyType"] === 'Commercial' || item["PropertyType"] === 'Business Opportunity' || item['SubType'] === 'Commercial' || item['SubType'] === 'Business' || item.PropertyType === 'Vacant Land' || item.PropertyType === 'Land/Boat Docks')) {
        status_class = 'badge-commercial_business badge-sale';
    }
    else if(item['ListingStatus'] === "Backup"){
        status = "Backup"
        status_class = 'badge-primary badge-sale';
    }
    
}
else if(item['ListingStatus'] === "ComingSoon"){
    status = "Coming Soon"
    status_class = 'badge-primary';
}else if(item['ListingStatus'] === "Closed"){
    status = "Sold"
    status_class = 'badge-danger';
}
/* else {
    status = "For Sale"
    status_class = 'badge-primary';
}
 */

if(item['Price_Diff'] !== 0 && item['Price_Diff'] !== '' && item['Price_Diff'] !== undefined && item['Old_Price'] !== undefined && item['Old_Price'] !== 0){
     str = "'"+item["Price_Diff"]+"'"
     pricecir = str.includes("-")?'Less':'More';
  
}
 
 let urlLink=`https://www.realstoria.com/${item.CityName.replaceAll(/\s/g,"-")}-homes-for-sale/${item.StreetNumber}-${item.StreetName.replaceAll(/\s/g,"-")}-${item.State}-${item.ZipCode}-mls-${item.ListingID_MLS}/popup`
 urlLink=urlLink.toLowerCase()
 //console.log(urlLink); 

  return (
    <>
    
    <div className="card bg-dark text-white overflow-hidden border-0 rounded-0 ls-card" >
    <a href={urlLink}
        className='popup-modal-custom'
            data-target="custom" 
            data-toggle="modal"
            data-url={urlLink}
            title={"MLS#" +item.MLS_NUM} 
            > 
        <div className="test-block mb-0 " >
       
            <Slider>
            {
                ("PhotoAll" in item  && item.PhotoAll.length > 0) ? 
                    item.PhotoAll.map((photo,i)=>{
                        return(
                            <LazyLoadImage  src={photo} 
                                            key={'img'+i+'-'+item.MLS_NUM}  
                                            className="card-img img-fluid" alt={'MLS# '+item.MLS_NUM}/>
                        )
                    })
                : item.Main_Photo_Url ?  
                    <LazyLoadImage src={item.Main_Photo_Url}  className="card-img img-fluid" alt={item.Address} key={"mainimg"+item.ListingID_MLS}/>
                    : <LazyLoadImage  src={require('../images/nophoto.png')}   className="card-img img-fluid" alt="nophoto"/>
            }
            </Slider>
           
            <div className="card-img-overlay anchort" >
                <span className={`sale-badge ${status_class} position-absolute`}>{status}</span> 
        
                <div className="description position-absolute dstyle">
           
                   
                    <h5>
                        {formatter.format(item.ListPrice)} <del className="text-white font-size-16"> {(item.Price_Diff !== 0 && item.Price_Diff !== '')?formatter.format(item.Old_Price):null}</del>
                    </h5>
                    <span className="badge badge-primary rounded-0 d-flex justify-content-center align-items-center text-uppercase">{item.PropertyType}</span>
                    <h5 className="mb-0 mt-1 text-white">{item.StreetNumber}{" "}{item.StreetName}</h5>
                    <h6 className="text-white">{item.CityName}{" , "}{item.State}{" "}{item.ZipCode}</h6>
                    <span>
                        <span className="ls_icon-block">
                            <span className="iconify bed-icon"
                                data-icon="fa6-solid:bed"></span>
                        {" "}{item.Beds} &nbsp;
                        </span>
                        <span className="ls_icon-block">
                            <span className="iconify bath-icon"
                                data-icon="fa:bath"></span>
                        {" "}{item.Baths} &nbsp;
                        </span>
                        <span className="ls_icon-block">
                            <span className="iconify resize-icon"
                                data-icon="fa:arrows-alt"></span>{" "}{format(item.SQFT)} &nbsp;
                        </span>

                    </span>
                    
                 {/*  </a>  */}
                </div>
            
               {pricecir ? <div className="price-cir">
                           <figure className="circle ">
                            {item.Price_Diff+"%"+" " +pricecir}
                            </figure>
                           </div>:null}
                        
            </div> 
           
        </div>
        </a> 
    </div>
   
   <div className=" position-absolute fav_iconblock" onClick={()=>dispatch(postAddToFavourite())}>
 {/*   <a href="javascript:void(0);" className="text-decoration-none d-flex justify-content-center align-items-center" > */}
    <div className="text-decoration-none d-flex justify-content-center align-items-center">
        <span className="iconify fav_icon text-primary"
            data-icon="streamline:interface-favorite-heart-reward-social-rating-media-heart-it-like-favorite-love"></span>
    </div>
  {/*  </a> */}
</div>


   

</>  
   
  )
}

export default PropertyBox
