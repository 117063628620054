import React,{useState,useEffect,useRef, useCallback} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import Slider from "react-slick";
import { selectFilter } from '../ThunkSlices/PropertyLIstSlice';
import { GoogleMap, MarkerClusterer,Marker,
         DrawingManager,Polygon,CircleF,InfoWindowF,OverlayView} from '@react-google-maps/api';
import PropertyBox from './PropertyBox';
import CommonAdsBox from './CommonAdsBox';


function GMapNew({setFilters, mapHeight,objCllbackRef,objCallbackClose,callbackCluster,callbackgridView}) {
  const dispatch = useDispatch();
  const drawingManagerRef = useRef(null);
  const {pLoading,objList,cityBound, arrPoints,listArr,commnAddrsList,totalRecord}=useSelector((state)=>state.propertyList);
  const [map, setMapState] = React.useState(null);
  const [circle, setCircle] = useState(null);
  const [colorFilter, setColorFilter] = useState(false);
  const [drawingMode, setDrawingMode] = useState(null);
  const [onDrawingComp, setOnDrawingComp] = useState(false);
  const [onCancelEdit, setOnCancelEdit] = useState(false);
  let [activeMarker, setActiveMarker] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const objSelFilters = useSelector(selectFilter);
  const [allClusterMarkers,setAllClusterMarkers]=useState([]);
  const [listArrnw,setListArrNw]=useState([]);
  const [commnAds_str,setCommnAds_str]=useState("");
  const [toggleViewMode, setToggleViewMode] = useState(false);
  const[userLat, setUserLat]= useState();
  const[userLong, setUserLong]= useState();

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  
  window.google = window.google || {};
  //const google = window.google;
  //let [objFilter,]=useState({});
  //////console.log(objList);
  let [mapZoomLevel, setMapZoomLevel]=useState(8);
  let [polyObj, setPolyObj]=useState({});
  let [objCircle, setCirObj]=useState({});
  let [isInitSearch, setNewSearch]=useState(false);
  let [enableZoom, setEnableZoom]=useState(false);
  let [bindBound, setBindBound]=useState(true);
  let [enableDisableZoom, setEDZoom] = useState(false)
  const mapRef = useRef(null);


  let objFilterNew = {}
  let isNewSearch = false;
  let zoomListenerEnabled = true;
  let allClusterMarkersold = [];
  var addrsData = [];
  var addrs=[]
  if(commnAddrsList.length > 0 ){
    commnAddrsList.map((sitem,i)=>{
      //////console.log(sitem);
     addrs = listArr.filter(val =>{
        return val.Address == sitem;
      
      });
      //////console.log(addrs );
     //addrsData=[...addrsData,...addrs]
    })
    //////console.log(addrsData);
  }
 
  const ColorIndicatorArr=[
    {
      id:1,
      property:'Price reduced',
      color:'#9c27b0',
      key:'pr'
    },
    {
      id:2,
      property:'Just listed',
      color:'#ffa500',
      key:'jl'
    },
    {
      id:3,
      property:'Condo +Town home + Villa',
      color:'#0404f7',
      key:'ctv'
    },
    {
      id:4,
      property:'House',
      color:'#096009', 
      key:'house'
    },
    {
      id:5,
      property:'Multi Family + Income',
      color:'#9f2626',
      key:'mfi'
    },
    {
      id:6,
      property:'Rent',
      color:'#ffeb3b',
      key:'rent'
    },
    {
      id:7,
      property:'Commercial + Business',
      color:'#7d7c7c',
      key:'cb'
    },
    {
      id:8,
      property:'Closed',
      color:'#ff0000',
      key:'clf'
    },
    {
      id:9,
      property:'Back up',
      color:'#43A6C6',
      key:'bu'
    },
  ]

  let [defaultCenter,setDefauldCenter]= useState({
    //lat: 25.761681, 
    //lng: -80.191788
    lat: ('clat' in objSelFilters)?objSelFilters.clat:('latitude' in objSelFilters)?parseFloat(objSelFilters.latitude):25.761681, 
    lng: ('clng' in objSelFilters)?objSelFilters.clng:('longitude' in objSelFilters)?parseFloat(objSelFilters.longitude):-80.191788
    // lat:25.758010000000000, 
    //lng:-80.192420000000000
  })
  
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }); 

  function converter (labelValue) {

    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
    
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(0) + "K"

    : Math.abs(Number(labelValue))
    
    ? (Math.abs(Number(labelValue)) / 1.0e+2) + "K"

    : Math.abs(Number(labelValue));
  }

  const format = num => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

  var mapStyles =
  [
      {
          "featureType": "administrative",
          "elementType": "labels.text.fill",
          "stylers": [
              {
                  "color": "#222222"
              }
          ]
      },
      {
          "featureType": "landscape",
          "elementType": "all",
          "stylers": [
              {
                  "color": "#ECEBE9"
              }
          ]
      },
      {
          "featureType": "poi",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "poi.business",
          "elementType": "geometry.fill",
          "stylers": [
              {
                  "visibility": "on"
              }
          ]
      },
      {
          "featureType": "road",
          "elementType": "all",
          "stylers": [
              {
                  "saturation": -100
              },
              {
                  "lightness": 45
              }
          ]
      },
      {
          "featureType": "road.highway",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "simplified"
              }
          ]
      },
      {
          "featureType": "road.arterial",
          "elementType": "labels.icon",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "transit",
          "elementType": "all",
          "stylers": [
              {
                  "visibility": "off"
              }
          ]
      },
      {
          "featureType": "water",
          "elementType": "all",
          "stylers": [
              {
                  "color": "#B1BDD6"
              },
              {
                  "visibility": "on"
              }
          ]
      }
  ];

  useEffect(() => {
    ////console.log("objSelFilters------------");
    // When we search with filter and after that search with city that time isInitSearch is getting TRUE at initial so our bound logic is not working because useEffect concider there is no chane with this variable so when we new filter in selected filter object that time we will set isInitSearch to false.
    // In sort, isInitSearch variable state is same that's why we false here to run bound logic.
    // TODO: Check with DRAG event and with other filter also on cluster click and if fond anything wrong then need some logic here.
    setNewSearch(false);
//console.log(objSelFilters);
    var objFl = {...objSelFilters};
    if(objFl && 'currentLocation' in objFl){
      // handleCurrentLocation()
      //////console.log(navigator.geolocation.getCurrentPosition );
     if(navigator.geolocation){
       navigator.geolocation.getCurrentPosition(position =>{
        setUserLat(position.coords.latitude);
        setUserLong(position.coords.longitude);
        //////console.log("==============",userLat, userLong);
        map.panTo({lat:position.coords.latitude,lng:position.coords.longitude})
        var latlng = {latitude:position.coords.latitude, longitude:position.coords.longitude};
        if('addtype' in objFl && ( objFl.addtype == 'cs' || objFl.addtype == 'zip' || objFl.addtype == 'mls' || objFl.addtype == 'bn' || objFl.addtype == 'address' || objFl.addtype == 'area'))
          delete objFl['addtype']; delete  objFl['addval']; delete objFl['currentLocation'];
        
        performSearch({...latlng, ...objFl})
     })
     }
   }
  }, [objSelFilters])

  useEffect(() => {
   // //console.log("3]------------useeffect arrpoint------------");
    setListArrNw([])
    setAllClusterMarkers([])
    if(arrPoints.length > 0){
      if('isNewSearch'  in objSelFilters && objSelFilters['isNewSearch'] === "1"){
        ////console.log("======  1 ========");
       // //console.log(isInitSearch);
        isNewSearch=true
        setNewSearch(isNewSearch);
        setEDZoom(false);
        setBindBound(true)
       
      }else if (defaultCenter.lat == '25.761681' && defaultCenter.lng == '-80.191788'){
       // //console.log("====== 2 ========")

        isNewSearch=true
        setNewSearch(isNewSearch);
        setEDZoom(false);
        setBindBound(true)
      }
    }
   
  }, [arrPoints])

 useEffect(() => {
 // //console.log("2]!!!!!!!!!!! useeffect citybound !!!!!!!!!!!!!");
    if(cityBound !== ''){
      //last value remove
     // var polygonString = cityBound.slice(0,-1);
      var polygonArray = cityBound.split('~');
      var coordinate = [];
        for(var i=0; i< polygonArray.length; i++)
        {
            var point =  polygonArray[i].split(" ");
            var LatLng = {lat: parseFloat(point[0]), lng: parseFloat(point[1])};
  
            coordinate.push(LatLng);
        }
        setPolygonCoordinates(coordinate);
  
       /*  const polyData = coordinate.reduce((result, item) => {
          return `${result}${item.lat}${item.lng}~`
         }, "");
       
         polyObj['poly']=polyData; */
    }
    else{
      clearPolygon()
    }
  }, [cityBound])  
  
  useEffect(() => {
   // //console.log('4]&&&&&&&&&& useeffect calbounds &&&&&&&&&&&&&&');
    ////console.log("isInitSearch:",isInitSearch);
   // //console.log("bindBound:",bindBound);
    if(isInitSearch){
      //bindBoundLogic(map,true)
      if(listArr.length > 0){
        const bounds = calculateBounds();
       // //console.log('=======bounds======');
        map.fitBounds(bounds);
        // Auto call ZOOM event
       /*  setBindBound(false)
        setNewSearch(false) */
      } 
    }else if(!bindBound)
      setEDZoom(true)

  }, [isInitSearch])

/* 
  useEffect(() => {
    //console.log("5]+++++++++useeffect bindBound+++++++++++++++++");
    //console.log('bindBound:', bindBound);
    // When comming first time and finished bound logic, after that enable zoom effect for use. 
    if(!bindBound)
      setEDZoom(true)

  }, [bindBound]) */

/*   useEffect(() => {
    ////console.log("1]*********useeffect currentLocation************");
    if(isCurrentLocation && 'currentLocation' in isCurrentLocation){
      // handleCurrentLocation()
      //////console.log(navigator.geolocation.getCurrentPosition );
     if(navigator.geolocation){
       navigator.geolocation.getCurrentPosition(position =>{
        setUserLat(position.coords.latitude);
        setUserLong(position.coords.longitude);
        //////console.log("==============",userLat, userLong);
        map.panTo({lat:position.coords.latitude,lng:position.coords.longitude})
        var latlng = {latitude:position.coords.latitude, longitude:position.coords.longitude};
        if('addtype' in isCurrentLocation && ( isCurrentLocation.addtype == 'cs' || isCurrentLocation.addtype == 'zip' || isCurrentLocation.addtype == 'mls' || isCurrentLocation.addtype == 'bn' || isCurrentLocation.addtype == 'address' || isCurrentLocation.addtype == 'area'))
          delete isCurrentLocation['addtype']; delete  isCurrentLocation['addval']; delete isCurrentLocation['currentLocation'];
        
        performSearch({...latlng, ...isCurrentLocation})
     })
     }
   }
   }, [isCurrentLocation]) */

  const handleMapLoad = (map) => {
  ////console.log('handleMapLoad');
    setMapState(map);
    //isNewSearch = true;
    //isNewSearch=true
    //setNewSearch(isNewSearch);
    //bindBoundLogic(map, true)
  };
  
 
  const onDragEnd=(k,val)=>{
   // //console.log('ON DRAG');
    //setLoading(true)
    let bound = map.getBounds();
    let ne = bound.getNorthEast();
    let sw = bound.getSouthWest();
    let mapCenter = map.getCenter();
    let NElat=ne.lat();
    let NElng=ne.lng();
    let SWlat=sw.lat();
    let SWlng=sw.lng();
    let dragbound={};
    var add= SWlat+ ',' +SWlng+ ','+NElat + ',' + NElng
    dragbound[k]=add;
    dragbound['clat']=mapCenter.lat();
    dragbound['clng']=mapCenter.lng(); 

    setDefauldCenter({
      lat: (dragbound['clat'])?dragbound['clat']:25.761681, 
      lng: (dragbound['clng'])?dragbound['clng']:-80.191788
    })
    handleFilters(dragbound);
  }

 const onZoomChanged=()=> {

    //console.log('ZOOMZOOMZOOMZOOMZOOMZOOMZOOM',"en:"+enableDisableZoom, "bind:"+bindBound);
    ////console.log("isInitSearch :"+isInitSearch);
   // 
    // When ZOOM event is auto called after bound logic that time we get both variable true so we update here to false for further process like eneable zoom event once finish initial all process.
    // This event is work only with reload : Need to cross check.
    if(bindBound === true && isInitSearch === true){
      setBindBound(false)
      setNewSearch(false)
    }

    if (enableDisableZoom) {
     // //console.log('BIND Zoom level:');
      handleZoomChange()
    }
  }

/**
   * This zoom event is bind first then other at initial
   * After that once get api data and bind bound logic that time also called this 
   * To prevent this
   * bindBound is default true we set it false once bound logic finish so that time enableDisableZoom variable is set true.
   * Now, when isNewSearch is true means if we perform any filter or reload page and once we get api data set enableDisableZoom as false so with bound logic zoom event will not bind.
   * The whole logic is when we trigger bound that time zoom event is also called and this is the default behaviour of google map if we enable zoom event.
   */

  const onDragStart=(e)=>{
    //setLoaded(false);
  }
  let _onZoomChanged=useCallback((event) => {
    //////console.log('IF onZoomChanged INIT');
    //////console.log(map);
    //////console.log(isInitSearch);
    //////console.log(enableZoom);
    // Do not call initially
    if(map != null && isInitSearch === false){
      setEnableZoom(!enableZoom)
      handleZoomChange()
      //////console.log('IN MAP');
    }
  }, [])

  const bindBoundLogic = (map, isNewSearch=false) => {    
    //console.log("boundlgc");
    // Run bound logic only when new search or at initial 
    //console.log(listArr);
    //console.log(isInitSearch, bindBound);
    //if(isInitSearch === true){
      if(listArr.length > 0){
        const bounds = calculateBounds();
        //console.log('bounds');
        map.fitBounds(bounds);
        setBindBound(false)
        setNewSearch(false)
      } 
    //}
  }
  const __handleMapLoad = (map) => {
    //////console.log('handleMapLoad');
  
    //mapRef.current = map;
    
    // Run bound logic only when new search or at initial 
    if(isNewSearch === true){

      //////console.log("map load");
       if(listArr.length > 0){
        const bounds = calculateBounds();
        //////console.log('bounds');
        map.fitBounds(bounds);
      } 
   
      //handleCurrentLocation(map);
     // map.panTo({lat: parseFloat(userLat), lng: parseFloat(userLong)})
      //var currentZoom = map.getZoom();
      //setMapZoomLevel(currentZoom)

       // Unbind Zoom Change Event if binded before
       /* if(zoomChangeListener)
       {
           window.google.maps.event.removeListener(zoomChangeListener);
           zoomChangeListener = '';
       } */
    }
    setMapState(map);
    // Bind Map Zoom Change Event 2015-10-12
   /*  if(zoomChangeListener == '' && map != null)
    {
      zoomChnageEvent(map);
    } */
  };

  const __onZoomChanged=()=>{
    //////console.log('onZoomChanged', isNewSearch);
    if(map != null ){

      if(zoomListenerEnabled == true){
        //////console.log('IF onZoomChanged');
        const newZoom = map.getZoom()
        let bound = map.getBounds();
        let ne = bound.getNorthEast();
        let sw = bound.getSouthWest();
        let mapCenter = map.getCenter();
        let NElat=ne.lat();
        let NElng=ne.lng();
        let SWlat=sw.lat();
        let SWlng=sw.lng();
        let dragZoombound={};
        ////console.log(isNewSearch);
        if(isNewSearch === false ){
          var add= SWlat+ ',' +SWlng+ ','+NElat + ',' + NElng
          dragZoombound['map']=add;
        }
        else{
          //setMapZoomLevel(newZoom)
        }
        
        dragZoombound['clat']=mapCenter.lat();
        dragZoombound['clng']=mapCenter.lng();
        dragZoombound['nzoom']=newZoom; 
        setDefauldCenter({
          lat: (dragZoombound['clat'])?dragZoombound['clat']:25.761681, 
          lng: (dragZoombound['clng'])?dragZoombound['clng']:-80.191788
        })
        
        handleFilters(dragZoombound);
      }
      else{
        ////console.log('ELSE onZoomChanged');
        // When clicking on cluster that time we disable the zoom event
        zoomListenerEnabled = true
      }
    }
  }
  const handleZoomChange = () => {
    //////console.log('ZOOOOOOMMMMMMMMMMMMMMM');
    //////console.log(map);
    if(zoomListenerEnabled == true){
      //////console.log('IF onZoomChanged');
      const newZoom = map.getZoom()
      let bound = map.getBounds();
      let ne = bound.getNorthEast();
      let sw = bound.getSouthWest();
      let mapCenter = map.getCenter();
      let NElat=ne.lat();
      let NElng=ne.lng();
      let SWlat=sw.lat();
      let SWlng=sw.lng();
      let dragZoombound={};
      //////console.log(isInitSearch);

      var add= SWlat+ ',' +SWlng+ ','+NElat + ',' + NElng
      dragZoombound['map']=add;

      dragZoombound['clat']=mapCenter.lat();
      dragZoombound['clng']=mapCenter.lng();
      dragZoombound['nzoom']=newZoom; 
      ///////////////////////
      // ADDED THIS NEW TO CHECKING DRAG EVENT WORKING PROPERLY OR NOT
      ///////////////////////
      //setMapZoomLevel(newZoom)
      setDefauldCenter({
        lat: (dragZoombound['clat'])?dragZoombound['clat']:25.761681, 
        lng: (dragZoombound['clng'])?dragZoombound['clng']:-80.191788
      })
      
      handleFilters(dragZoombound);
    }
    else{
      //////console.log('ELSE onZoomChanged');
      // When clicking on cluster that time we disable the zoom event
      zoomListenerEnabled = true
    }
    
  };
  const handleFilters = (filterVal) => {
    //objFilter = Object.assign(objFilter, filterVal);
    ////console.log('handleFilters');
    //////console.log(objFilterNew);
    objFilterNew = {...objFilterNew, ...objSelFilters, ...filterVal};
    // When perform drag and zoom that time we get newZoom false, because we have to perform API call and get data based
    if(isNewSearch === false || 'currentLocation' in objFilterNew){
      ////console.log('IF handleFilters');
      delete objFilterNew['isNewSearch']
      delete objFilterNew['currentLocation']
      performSearch(objFilterNew)
    }
    else{
      ////console.log('ELSE handleFilters');
      isNewSearch=false
      setNewSearch(isNewSearch);
      ////console.log("isInitSearch====",isInitSearch);
    }
  }
    
  const handleCheckbox=(item)=>{
    let filterObj={};
    let Id=item.id;
    let filterArrObj={}

    var objFltr = {}
    // We can not directly edit objSelFilteres objetc, so to edit selected filter we have to merge it with new created object
    objFltr = {...objSelFilters, ...objFltr}

    if(('clfr' in objFltr) == false){
      objFltr['clfr'] = [];
    }
    //console.log(objFltr);

    if(!Array.isArray(objFltr['clfr']) && objFltr['clfr'] != ''){
      //console.log("objFltr");
      let arrClfr=[objFltr['clfr']]
      objFltr['clfr']=arrClfr
    }
    
    if('ptype' in objFltr && !Array.isArray(objFltr['ptype'])){
      //console.log("123");
      let arrClfr=[objFltr['ptype']]
      objFltr['ptype']=arrClfr
    }

    if('stype' in objFltr && !Array.isArray(objFltr['stype'])){
      //console.log("456");
      let arrClfr=[objFltr['stype']]
      objFltr['stype']=arrClfr
    }

    if('status' in objFltr && !Array.isArray(objFltr['status'])){
     let arrClfr=[objFltr['status']]
      objFltr['status']=arrClfr
    }

    if('clfr' in objFltr && objFltr['clfr'].indexOf(item.key) >= 0 ){
      var spliced = objFltr['clfr'].filter((val) => val!=item.key)
      objFltr['clfr'] = spliced
    }else{
      objFltr['clfr'] = [...objFltr['clfr'], ...[item.key]]
    }


    //pr, jl
    if( Id===1){
      if(('ispricereduce' in objFltr) === true){
        delete objFltr['ispricereduce'];
      }else{
        let PriceDiff=0
        filterObj['ispricereduce']=PriceDiff
      }

    }else  if( Id===2){
      if(('dom' in objFltr) === true){
        delete objFltr['dom'];
      }else{
        let doms=7+"-";
        filterObj['dom']=doms;
    }

  }else  if( Id===3){

    var arrST = ["Condominium", "Townhouse" , "Villa"];
    // If stype is already set from any ptype group
    if( ('stype' in objFltr) === true && objFltr['stype'].length > 0){
      var resultST = arrST.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')

      // If already set arrST array in filter then remove that data from filter
      // else set this data in filter means add arrST array in filter
      if(resultST.length > 0){
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrST.indexOf(val) < 0)?val:'');
      }else{
        objFltr['stype'] = [...objFltr['stype'], ...arrST];
      }
    }else {
        // If stype is not set
          objFltr['stype']=arrST; 
    }

   

}else  if( Id===4){

  var arrPT = ["Residential"];
    if( ('ptype' in objFltr) === true && objFltr['ptype'].length > 0){
      var result = arrPT.filter((val) =>  (objFltr['ptype'].indexOf(val) >= 0)?val:'')
      
        if(result.length > 0){
          objFltr['ptype'] = objFltr['ptype'].filter((val) => (arrPT.indexOf(val) < 0)?val:'')
        }
        else{
           objFltr['ptype'] = [...objFltr['ptype'], ...arrPT];
        }
      } else {
        filterObj['ptype']=arrPT
        
      }  


}else  if( Id===5){

  var arrSH = ["MultiFamily","Duplex","Quadruplex","Triplex"];
  if( ('stype' in objFltr) === true && objFltr['stype'].length > 0){

      var resultSH = arrSH.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')
    
      if(resultSH.length > 0){
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrSH.indexOf(val) < 0)?val:'')
      }
      else{
        objFltr['stype'] = [...objFltr['stype'], ...arrSH];
      }
    }else {
        objFltr['stype']=arrSH;
       
      }  


}else  if( Id===6){

  const arrPR = ["Rental"]
    if( ('ptype' in objFltr) === true && objFltr['ptype'].length > 0){
    
    var resultPR = arrPR.filter((val) =>  (objFltr['ptype'].indexOf(val) >= 0)?val:'')
    
      if(resultPR.length > 0){
        objFltr['ptype'] = objFltr['ptype'].filter((val) => (arrPR.indexOf(val) < 0)?val:'')
      }
      else{
        objFltr['ptype'] = [...objFltr['ptype'], ...arrPR];
      }
       
    }else {
      filterObj['ptype']=arrPR;
      }  

     

}else  if( Id===7){
  var arrPC = ["BusinessOpportunity","Vacantland"];
  var arrST = ["Commercial"];
    if( ('ptype' in objFltr) === true && objFltr['ptype'].length > 0 ){
      var resultPC = arrPC.filter((val) =>  (objFltr['ptype'].indexOf(val) >= 0)?val:'')
      // Remove
      if(resultPC.length > 0){
        objFltr['ptype'] = objFltr['ptype'].filter((val) => (arrPC.indexOf(val) < 0)?val:'')
      }
      else{
        // Add to filter
        objFltr['ptype'] = [...objFltr['ptype'], ...arrPC];
      }

    }else {
      // Add to filter
      filterObj['ptype']=arrPC
    }

    if( ('stype' in objFltr) === true && objFltr['stype'].length > 0 ){
      var resultPC = arrST.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')
      // Remove
      if(resultPC.length > 0){
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrST.indexOf(val) < 0)?val:'')
      }
      else{
        // Add to filter
        objFltr['stype'] = [...objFltr['stype'], ...arrST];
      }
    }
    else {
      // Add to filter
      filterObj['stype']=arrST
    }
}else  if( Id===8){

  if(('status' in objFltr) === true && Array.isArray(objFltr['status']) && objFltr['status'].indexOf("Closed") >= 0 ){
    objFltr['status'] = objFltr['status'].filter((val) => (val != 'Closed' && val != 'closed')?val:'')
    
    }else{
      let status=["Closed","closed"]
      if(('status' in objFltr) === true && Array.isArray(objFltr['status']))
      objFltr['status'] = [...objFltr['status'], ...status];
      else
        objFltr['status'] = status;
       
    }

  }else  if( Id===9){
    if(('status' in objFltr) === true && Array.isArray(objFltr['status']) && objFltr['status'].indexOf('Backup') >= 0){
      objFltr['status'] = objFltr['status'].filter((val) => (val != 'Backup')?val:'')  
    }else{
      let status= ["Backup"];
      if(('status' in objFltr) === true && Array.isArray(objFltr['status']))
          objFltr['status'] = [...objFltr['status'], ...status];
      else
          objFltr['status'] = status;
          
    }
  }


  

/*   if( Id===1){
      if(('ispricereduce' in objFltr) === true){
        delete objFltr['ispricereduce'];
      }else{
        let PriceDiff;
        PriceDiff=-1
        //console.log(PriceDiff);
        filterObj['clfr'] = {'ispricereduce':PriceDiff}
      }

  }else if(Id===2){
      if(('dom' in objFltr) === true){
        delete objFltr['dom'];
        delete objFltr['clrDom'];
      }else{
        let doms=7+"+";
        filterObj['clfr']={'dom': doms};
    }

  }else if(Id===3){
    
    var arrST = ["Condominium", "Townhouse" , "Villa"];
    if( ('stype' in objFltr) === true && objFltr['stype'].length > 0){
      var resultST = arrST.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')

      if(resultST.length > 0){
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrST.indexOf(val) < 0)?val:'');
      }else
          objFltr['stype'] = [...objFltr['stype'], ...arrST];
    }else {
          objFltr['stype']=arrST; 
    }  
          
  }else if(Id===4){
    
    var arrPT = ["Residential"];
    if( ('ptype' in objFltr) === true && objFltr['ptype'].length > 0){
      var result = arrPT.filter((val) =>  (objFltr['ptype'].indexOf(val) >= 0)?val:'')
      
        if(result.length > 0)
          objFltr['ptype'] = objFltr['ptype'].filter((val) => (arrPT.indexOf(val) < 0)?val:'')
        else
          objFltr['ptype'] = [...objFltr['ptype'], ...arrPT];
      } else {
        filterObj['ptype']=arrPT
        
      }  
      

    var arrSR = ["SingleFamilyResidence"];
    if(('stype' in objFltr) === true && objFltr['stype'].length > 0){

      var resultSR = arrSR.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')
    
      if(resultSR.length > 0)
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrSR.indexOf(val) < 0)?val:'')
      else
        objFltr['stype'] = [...objFltr['stype'], ...arrSR];
          
    }else{
        filterObj['stype']=arrSR
      
      }    

  }else if(Id===5){
  var arrSH = ["Multi Family","MultiFamily","Duplex","Quadruplex","Triplex"];
  if( ('stype' in objFltr) === true && objFltr['stype'].length > 0){

      var resultSH = arrSH.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')
    
      if(resultSH.length > 0)
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrSH.indexOf(val) < 0)?val:'')
      else
        objFltr['stype'] = [...objFltr['stype'], ...arrSH];
    }else {
        objFltr['stype']=arrSH; 
       
      }  
      
  }else if(Id===6){

    const arrPR = ["Rental"]
    if( ('ptype' in objFltr) === true && objFltr['ptype'].length > 0){
    
    var resultPR = arrPR.filter((val) =>  (objFltr['ptype'].indexOf(val) >= 0)?val:'')
    
      if(resultPR.length > 0)
        objFltr['ptype'] = objFltr['ptype'].filter((val) => (arrPR.indexOf(val) < 0)?val:'')
      else
        objFltr['ptype'] = [...objFltr['ptype'], ...arrPR];
    }else {
      filterObj['ptype']=arrPR;
      }  
        

  }else if(Id===7){
    
    var arrPC = ["Commercial","Businessopportunity","Vacantland"];
    if( ('ptype' in objFltr) === true && objFltr['ptype'].length > 0 ){

      var resultPC = arrPC.filter((val) =>  (objFltr['ptype'].indexOf(val) >= 0)?val:'')
      
      if(resultPC.length > 0)
        objFltr['ptype'] = objFltr['ptype'].filter((val) => (arrPC.indexOf(val) < 0)?val:'')
      else
        objFltr['ptype'] = [...objFltr['ptype'], ...arrPC];

    }else {
      filterObj['ptype']=arrPC
     
    }  

    var arrSB = ["Commercial","BuildingBusiness"];
    if(('stype' in objFltr) === true && objFltr['stype'].length > 0){

      var resultSB = arrSB.filter((val) =>  (objFltr['stype'].indexOf(val) >= 0)?val:'')
    
      if(resultSB.length > 0)
        objFltr['stype'] = objFltr['stype'].filter((val) => (arrSB.indexOf(val) < 0)?val:'')
      else
        objFltr['stype'] = [...objFltr['stype'], ...arrSB];
          
      }else{
        filterObj['stype']=arrSB
      }

    if(('status' in objFltr) === true && Array.isArray(objFltr['status']) && objFltr['status'].indexOf('Active') >= 0){
      objFltr['status'] = objFltr['status'].filter((val) => (val != 'Active')?val:'')
    }
    else{
        let status=["Active"]
        
        //filterObj['status']=status;
        if(('status' in objFltr) === true && Array.isArray(objFltr['status']))
          objFltr['status'] = [...objFltr['status'], ...status];
        else
          objFltr['status'] = status;
          
    }

  }else if(Id===8){

    if(('status' in objFltr) === true && Array.isArray(objFltr['status']) && objFltr['status'].indexOf("Closed") >= 0 ){
      objFltr['status'] = objFltr['status'].filter((val) => (val != 'Closed' && val != 'closed')?val:'')
      
      }else{
        let status=["Closed","closed"]
        if(('status' in objFltr) === true && Array.isArray(objFltr['status']))
        objFltr['status'] = [...objFltr['status'], ...status];
        else
          objFltr['status'] = status;
         
      }

  }else if(Id===9){
    if(('status' in objFltr) === true && Array.isArray(objFltr['status']) && objFltr['status'].indexOf('Backup') >= 0){
      objFltr['status'] = objFltr['status'].filter((val) => (val != 'Backup')?val:'')  
    }else{
      let status= ["Backup"];
      if(('status' in objFltr) === true && Array.isArray(objFltr['status']))
          objFltr['status'] = [...objFltr['status'], ...status];
      else
          objFltr['status'] = status;
          
    }
  } */
 
  objFltr = {...objFltr, ...filterObj}
  //handleFilters(filterObj);
  performSearch(objFltr)
  }

const performSearch= (objFltr) =>{
  setFilters((currentState) => {
    return objFltr;
  })
}
const handleActiveMarker= (marker) =>{
  if (marker === activeMarker) {
       return;
     }
     setActiveMarker(marker);
};

const selectedItem=(item,i)=>{
  //setMapZoomLevel(mapZoomLevel)
  objCllbackRef(item.ListingID_MLS); 
  handleActiveMarker(item.ListingID_MLS)
}

const onCloseClick=(item)=>{
  setActiveMarker(null);
  objCallbackClose(item.ListingID_MLS);
 }

const onLoad = (drawingManager) => {
   drawingManagerRef.current = drawingManager;
};

const onPolygonComplete = polygon => {
  setDrawingMode(null);
  setOnDrawingComp(true);
  setOnCancelEdit(true);
  setPolygon(polygon);
  const path = polygon.getPath();
  const coordinates = path.getArray().map((coord) => ({ lat: coord.lat(), lng: coord.lng() }));
  setPolygonCoordinates(coordinates);
  const polyData = coordinates.reduce((result, item) => {
   return `${result}${item.lat}${item.lng}~`
  }, "");

  polyObj['poly']=polyData;
  //handleFilters(polyObj)
}


const onCircleComplete = circle => {
  setOnDrawingComp(true);
  setOnCancelEdit(true);
  if (!circle) return null;
  setCircle(circle);
  const center = circle.getCenter();
  const radius = circle.getRadius();
  const latLng = { lat: center.lat(), lng: center.lng() };
  let cir=radius+"~"+center.lat()+"~"+center.lng();
  objCircle['cir']=cir;
  //handleFilters(objCircle)
  
  return {
    center: latLng,
    radius: radius,
    circle: circle,
  };
}

const handleCustomButtonClick = (mode) => {
  setDrawingMode(mode);
  setOnDrawingComp(true);
  //map.panTo({lat: parseFloat(userLat), lng: parseFloat(userLong)})
  };

const handleApply=()=>{
  if(polyObj){
    handleFilters(polyObj);
  }
  if(objCircle){
    handleFilters(objCircle);
  }
  
  setOnDrawingComp(false);
  setDrawingMode(null);
};

const clearPolygon=()=>{
  setDrawingMode(null);
  setOnDrawingComp(false);
  setPolygonCoordinates([]);
  setOnCancelEdit(false);
}

 const handleCancel=()=>{

  clearPolygon()

  if (polygon) {
    polygon.setMap(null);
    setPolygon(null);
    setPolyObj({})
  }

  if (circle) {
    circle.setMap(null);
    setCircle(null);
    setCirObj({})
  }
}

const handleRemoveDrawing=()=>{
  setOnCancelEdit(false);
  setPolygonCoordinates([]);
  setDrawingMode(null);
  setOnDrawingComp(false);
  if (polygon) {
    objFilterNew = {...objFilterNew, ...objSelFilters}
    delete objFilterNew.poly;
    //delete objFilterNew.cir;
    polygon.setMap(null);
    setPolyObj({})
    setPolygon(null);
    performSearch(objFilterNew)
  }
 
  if (circle) {
    objFilterNew = {...objFilterNew, ...objSelFilters}
    delete objFilterNew.cir;
   // delete objFilterNew.poly;
   setCirObj({})
    circle.setMap(null);
    setCircle(null);
    performSearch(objFilterNew)
  }
 
}

const calculateBounds = () => {
  //////console.log("calcu bound");
  const bounds = new window.google.maps.LatLngBounds();
  arrPoints.forEach((marker) => {
    bounds.extend(marker.position);
  });
  return bounds;
};


const handleGridView=()=> {
  setToggleViewMode(!toggleViewMode)
  callbackgridView(toggleViewMode);
}
/* useEffect(() => {
  if (map != null) {
    //const map = map.current;

   zoomChnageEvent(map)

    // Rest of your useEffect code
    return () => {
      window.google.maps.event.removeListener(zoomChangeListener);
    };
  }
}, []); */


/**
 * Initail load
 * 
 * 
 * 
 * 
 * =======
 * On cluster click 
 * Write logic based on allClusterMarkers variable if this have data then set logic as per that otherwise run our common logic.
 * Reset pagination - Based on cluster total markers
 * Reset total records - will be cluster total markers
 * Pagination will be start from 1st page.
 * 
 * If you perform any filter action then allClusterMarkers data will be blank
 * 
 * arrData = 
 * arrData = all data listArr
 * 
 * @param {*} cluster 
 */
const onClusterClick=(cluster,title)=>{
//////console.log(cluster);
  if(cluster != undefined){
    zoomListenerEnabled = false;
   if(cluster.clusterIcon.className =="common_ads"){
      //////console.log("+++++++++++++");
      setCommnAds_str(title);
    }else{
      allClusterMarkersold = cluster.getMarkers();
      if(allClusterMarkersold.length > 0){
        var arrProcData = [];
  
        allClusterMarkersold.map((item,i)=>{
          var d = listArr.filter(val => val.ListingID_MLS == item.title);
          arrProcData = [...arrProcData,...d];
        })
        callbackCluster(arrProcData);
        setAllClusterMarkers(allClusterMarkersold);
        setListArrNw(arrProcData);
        setCommnAds_str("")
    }
    var bounds = cluster.getBounds();
    map.fitBounds(bounds);
   
  }   
}




 /*  
  if(zoomChangeListener)
    window.google.maps.event.removeListener(zoomChangeListener);

  if (cluster != undefined){
    
  }
   
  zoomChnageEvent(map)
 */

  /*
   {
      // Load first page data
      getMapResultData(1);

      //if(current_cluster.length <= 24){
      var bounds = cluster.bounds;

      clearOverlays();

      for (var i = 0; i < current_cluster.length; i++) {
          current_cluster[i].setMap(map);
          markers.push(
              current_cluster[i]
          );
      }
      if (objMapZoomEvent) {
          google.maps.event.removeListener(objMapZoomEvent);
      }
      map.fitBounds(bounds);
      zoom_change();
  }
  */
}
/*clearOverlays = ()=> {
  // Remove old Markers From Map, If any
  if ((isCluster == true && typeof (isCluster) != undefined)) {

      if (markerClusterer_new) {
          for (i in markerClusterer_new) {
              markerClusterer_new[i].removeMarkers(markers);
              markerClusterer_new[i].clearMarkers();
          }
          markerClusterer_new.length = 0;
      }

  }
  if (markers) {
      for (i in markers) {
          markers[i].setMap(null);
      }

      markers.length = 0;
  }
  if (markers1) {
      for (i in markers1) {
          markers1[i].setMap(null);
      }

      markers1.length = 0;
  }

  if (typeof objIB_Big !== 'undefined')
      objIB_Big.hide();

  if (typeof objIB_Small !== 'undefined')
      objIB_Small.hide();

  zindex = 1;

  // Remove polygon, if any
  if (allPolyObj)
      allPolyObj.setMap(null);
}*/


return (
  
    <div>     

 {colorFilter ? 
 <form id="ms-filter-form-color" method="post" role="form" className="ms-filter-form-color">
        <div className="pro-type-info" > 
          <ul className="list-unstyled">
            {ColorIndicatorArr.map((item,i)=>{
              return(<>
                <li className="colorind_price_reduced d-flex" key={"colorarr" +item.id}>
                  <div>
                  <input key={'clr'+item} type="checkbox" 
                         id={item} name="clfr[]"
                         value={item} 
                         onChange={()=>handleCheckbox(item)} 
                         checked={('clfr' in objSelFilters)==true  && (objSelFilters['clfr'].indexOf(item.key) >= 0) ? 'checked' :""}/>
                  </div>
                  <span>
                 <div style={{backgroundColor:item.color,padding:7,margin:3,}}>
                 
                  </div>
                  </span>&nbsp;&nbsp;{item.property}
                 
                </li> 
                <>
                  {item.property == 'Multi Family + Income'? <hr className='bg-secondary m-0' style={{width:'100%'}}/> :null}  
                  </> 
                </>
              )
            })}
          </ul>
        </div> 
  </form>:null}

 {onDrawingComp ?
  <div id="draw-shape" className="draw-shape" style={{display:'block'}}>
    <div className="pull-right draw-opt"> 
    <a href="#/" className="draw-cancel f-c-white ml-2" id="draw-cancel" title="Cancel" onClick={()=>handleCancel()}>Cancel</a> 
    <a href="#/" className="draw-apply f-c-white ml-2" id="draw-apply" title="Apply" onClick={()=>handleApply()}>Apply</a> 
    </div>
  </div>
  :null}
        
  <div className="map-control-btn position-absolute ">
    <div className="mb-2 grid_view_btn ">
    <a className="btn bg-white btn-sm btn-grid text-uppercase rounded-0" id="btn_grid_on" data-mode="grid" href="#!" title="Grid View" 
       onClick={() => handleGridView()}>
      <i className="fa fa-th fa-md"></i> Grid View</a>
    </div> 
          

 {polygonCoordinates.length <= 0 && !circle ? 
    <a className="btn bg-primary rounded-0 btn-draw text-white" id="btn_draw" data-mode="draw" href="#!"
      title="Draw"   onClick={() => handleCustomButtonClick(window.google.maps.drawing.OverlayType.POLYGON)}>
     <i className="fa fa-pencil fa-lg"></i>
  </a>
  :null}
  {polygonCoordinates.length <= 0 && !circle ? <a className="btn rounded-0 btn-circle m-1 text-white" id="btn_circle" data-mode="draw" href="#!"
      title="Circle Draw"  onClick={() => handleCustomButtonClick(window.google.maps.drawing.OverlayType.CIRCLE)}>
      <i className="fa fa-circle-o fa-lg"></i>
  </a>
  :null}


  { polygonCoordinates.length > 0 || circle ?  
      <a className="btn btn-danger btn-remove mr-1 text-white" id="btn_remove" data-mode="draw" href="#/" title="Clear Polygon" 
        onClick={()=>handleRemoveDrawing()}>
        <i className="fa fa-close fa-lg"></i>
      </a>
  :null}
      
      <a className="btn btn-indicator rounded-0 text-white" id="btn_color_indicator" href="#!"
          title="Color Indicator" onClick={()=>setColorFilter(!colorFilter)}>
         <i className="fa fa-info fa-lg"></i>
      </a>
  </div> 

           
  <GoogleMap
      onLoad={handleMapLoad}
      onDragStart={onDragStart}
      onDragEnd={(e)=>onDragEnd('map',e)}
      onZoomChanged={onZoomChanged}
      mapContainerStyle={{ width: '100%', height: mapHeight+'px' }}
      center={defaultCenter}
      zoom={mapZoomLevel} 
      ref={mapRef}
      options={{ styles: mapStyles,streetViewControl:true,mapTypeControlOptions:{style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,position:window.google.maps.ControlPosition.LEFT_BOTTOM}}}>

      {
         (commnAds_str != "" &&  commnAds_str in objList) ? 
          <InfoWindowF mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                       position={{lat:parseFloat(objList[commnAds_str]['data'][0].Latitude),lng:parseFloat(objList[commnAds_str]['data'][0].Longitude)}}
                       options={{ pixelOffset: new window.google.maps.Size(0, 2), minHeight:220, maxWidth:664 }}
                       onCloseClick={()=>setCommnAds_str('')}
                       onLoad={(infoWindow)=>{}}>
                  <CommonAdsBox  data={objList[commnAds_str]['data']} />
                </InfoWindowF>:null } 
                  
                 
       
         { allClusterMarkers.length > 0 ? 
           allClusterMarkers.map((marker,i)=>{
            return(
              <Marker
                key={"markerlist"+listArrnw[i].ListingID_MLS}
                position={marker.position}
                label={marker.label}
                id={i}
                onClick={()=>selectedItem(listArrnw[i],i)} 
                title={marker.title}
                icon={marker.icon}>
                {activeMarker == marker.title ? (
                  <InfoWindowF mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                              position={{lat:parseFloat(marker.lat),lng:parseFloat(marker.lng)}}
                              options={{ pixelOffset: new window.google.maps.Size(0, 2)}}
                              onCloseClick={()=>onCloseClick(listArrnw[i])}
                              onLoad={(infoWindow)=>{
                          }} >
    
            <PropertyBox  data={listArrnw[i]} />
    
              </InfoWindowF>) : null}  
            </Marker>
            )}
        )
          :
          Object.keys(objList).map((item,i)=>{
         // ////console.log(objList[item]);
          let clusterStyle= objList[item]['style']; 

          //////console.log(clusterStyle);
          if('data' in objList[item] && objList[item]['data'].length > 0)
       
          return(
            <MarkerClusterer  key={"cluster"+item}
                              options={{ imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                              styles: [clusterStyle],
                              clusterClass:objList[item]['class'] }}
                              onClick={(cluster)=>onClusterClick(cluster,objList[item]['title'])}
                              zoomOnClick={false}>
             {(clusterer) =>
          
            objList[item]['data'].map((marker,i)=>{
              let colorCode=clusterStyle['textColor'];
              // Aena border color and font color same 6e realstoria.com ma
              
              return(
                <Marker
                  key={marker.ListingID_MLS}
                  position={{lat:parseFloat(marker.Latitude),lng:parseFloat(marker.Longitude)}}
                  clusterer={clusterer}
                  label={{text:""+"$"+""+converter(marker.ListPrice)+"",color:colorCode, fontSize: "12px",}}
                  id={i}
                  onClick={()=>selectedItem(marker,i)} 
                  title={marker.ListingID_MLS}
                  icon={{
                      path: 'M -3,-1.1 3,-1.1 3,1.1 -3,1.1 z',
                      fillColor:clusterStyle['color'],
                      fillOpacity: 1,
                      strokeColor:clusterStyle['strokeColor'],
                      strokeWeight: 2.6,
                      scale: 9,
                      strokeWeight:2,
                      size:10
                     }}>
                    {activeMarker == marker.ListingID_MLS ? (
                    <InfoWindowF mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                 onCloseClick={()=>onCloseClick(marker)}
                                 position={{lat:parseFloat(marker.Latitude),lng:parseFloat(marker.Longitude)}}
                                  //options={infoBoxOpts}
                                 options={{ pixelOffset: new window.google.maps.Size(0, 2)}}
                                 onLoad={(infoWindow)=>{
                            }} >
                              <PropertyBox data={marker} key={marker.ListingID_MLS}/>

                 </InfoWindowF>) : null}  
              </Marker>
              
                )
                
              }
          )
    } 
            </MarkerClusterer>
          )
          })
        } 

  <div className='map-pager-container d-flex justify-content-center w-100'>
      <div className='map-pager px-5'> 
        {listArr.length>0 ?  
          <span className='text-white'>Only Showing 600 Properties. Zoom in or narrow your search.</span> 
          :
          <span className='text-white'>No data found,Please modify your criteria and search again.</span>
        }
      </div>
  </div>
          
  {pLoading?   
        <div itemscope="" itemtype="https://schema.org/Property" id="lr-result-loader" className="hide-me d-flex justify-content-center w-100" > 
        <div className='px-5 py-1' id="lr-result-loader-child">
          <img src="https://www.realstoria.com/templates/images/ajax-loader-small.gif" alt="loader"/>&nbsp;Loading...
        </div> 
      </div>
    :null}


<div>
 <DrawingManager
    ref={drawingManagerRef}
      onLoad={onLoad}
      onPolygonComplete={onPolygonComplete}
      onCircleComplete={onCircleComplete}
      options={{
        drawingControl: false,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [window.google.maps.drawing.OverlayType.POLYGON,window.google.maps.drawing.OverlayType.CIRCLE]
        },
        polygonOptions: { editable: false ,},
        circleOptions: { editable: false ,}
      }}
      drawingMode={drawingMode}
      onDrawingModeChanged={(newMode) => setDrawingMode(newMode)}
    />
</div>

{polygonCoordinates.length > 0 && (
          <Polygon  path={polygonCoordinates} options={{ fillColor: '#1C00ff00', fillOpacity: 0.3 }} />
        )}

    
{circle && (
          <CircleF
            center={circle.getCenter()}
            radius={circle.getRadius()}
            options={{ fillColor: '#1C00ff00', fillOpacity: 0.3 }}
          /> )}

   
  </GoogleMap>
 
    </div>
  )
}

export default GMapNew;
