import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const postRemoveFavourite = createAsyncThunk('removefavList/postRemoveFavourite',
async(_,{rejectWithValue})=>{
 


try {
    const postdata = {
        "key":"e655785894fde4dcd1c610d0a722e5bc",
        "module":"user",
        "action":"managefavorite",
        "filter":JSON.stringify({ "user_email":"t11@gmail.com",
                                  "mls_num":"R10670046-3",
                                  "action":"Remove"
                                })
    };

 
    let headers = {
       "Content-Type": "application/x-www-form-urlencoded"
      
    }

   const res = await axios.post("https://www.realstoria.com/api/index.html",postdata ,{headers});
   console.log("Removefavlist---------------",(res.data));
    return (res.data)
    }catch(error){
        rejectWithValue(error.res.data)
        console.log('error');
      }
    }
);

export const PostRemoveFavSlice=createSlice({
    name:"removefavList",
    initialState:{
    Removefav:[],
    RMLoading:false,
    },
    reducers:{},
    extraReducers:(builder)=>{
    builder
      .addCase(postRemoveFavourite.pending, (state, action) => {
        state.RMLoading=true;
    })
     .addCase(postRemoveFavourite.fulfilled, (state, action) => {
        state.RMLoading=false;
        state.Removefav=action.payload;
    })
     .addCase(postRemoveFavourite.rejected, (state, action) => {
       
        state.RMLoading=false;
    })
  }
})

export default PostRemoveFavSlice.reducer