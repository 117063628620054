import React from 'react';

function CommonAdsBox(props) {
  let Data=props.data;

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
   });

const format = num =>
   String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

 
  return (
    <div className='cluster_popup '  style={{width: 330}}>
    <div className='col-sm-12 col-md-12 col-lg-12 p-1'>
    {Data.map((item,i)=>{
        return(
            <div className='row'  key={"cmnAdrs"+item.MLS_NUM}>
            <div className="col-4 mb-2">    
            <div className="image">  
                {
                    item.Main_Photo_Url ?  
                        <img src={item.Main_Photo_Url}  className="card-img " alt={item.address} key={"comnadrs"+item.ListingID_MLS} style={{height:100,width:100}}/>
                        : 
                        <img  src={require('../images/nophoto.png')}  className="card-img " alt="nophoto" key={"nophoto"} style={{height:100,width:100}}/>
                } 
            </div>
            </div>
            <div className='col-8 ' >
                <div className="item- p-1" data-id={item.ListingID_MLS}>     
                    <div className="cluster_description m-1- -w-100">          
                        <div className="row">  
                            <div className="col-12"> {formatter.format(item.ListPrice)} </div>  
                                <div className="col-12 mb-0 mt-1"> {item.StreetNumber} &nbsp;{item.StreetName} &nbsp;{item.CityName}&nbsp;{item.State} </div>                              
                                    <span className="col-12 mb-0 mt-1"> &nbsp;{item.ZipCode}</span> 
                                        <div className="col-12 mt-1"> 
                                            <span><i className="fa fa-bed"></i>{" "}{item.Beds}&nbsp; &nbsp;&nbsp;</span> 
                                            <span><i className="fa fa-bath"></i> {" "}{item.Baths}&nbsp; &nbsp;&nbsp;</span>  
                                            <span><i className="fa fa-arrows-alt"></i>{" "}{format(item.SQFT)}&nbsp; &nbsp;&nbsp;</span>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
            </div>
            
        )
    })}
       
    
    


</div>  
</div>
   
  )
}

export default CommonAdsBox

